import { makeStyles, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useGetCurrentUserQuery, useGetMatterCommentsForMeQuery, useUpdateMatterCommentMutation, useUpdateMatterManagerMutation, useUpdateWorkloadMessageMutation } from "utils/wemble-api";
import { useDebounce, usePrevious } from 'hooks';
import { useEffect, useState } from "react";
import organization from "utils/organization";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F7F7F7",
    height: 220,
    position: 'relative',
    borderRadius: 24,
    transition: '0.2s',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    padding: '12px',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },

  message__input: {
    backgroundColor: 'transparent',
    color: theme.palette.gray.g1,
    padding: 0,
    width: '100%',
    height: '100%',
    border: 'none',
    position: 'relative',
    resize: 'none',
    lineHeight: 1,
    fontWeight: 500,
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-scrollbar': {
      width: 0,
    },
    display: '-webkit-box',
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 11.8,

  },
  message__input_expanded: {
    fontSize: 12,
    lineHeight: 1.15,
    height: '42px'

  },
  table: {
    '& .MuiTableCell-root': {
      fontSize: 11,
      borderBottomColor: "#CCCCCC",
      padding: '4px 2px',
      [theme.breakpoints.down('sm')]: {
        padding: '2px 2px',
      },
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
      border: 'none',
    },
  },
  matter__cell: {
    maxWidth: '90px !important',
  },
  matter__manager: {
    backgroundColor: "#EEEEEE",
    padding: '2px 4px 2px 4px',
    marginLeft: '4px',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: 9
  },
  matter__manager_active: {
    backgroundColor: theme.palette.blueGray.bg1,
    color: 'white'
  },
  comment__cell: {
    backgroundColor: '#FFFFFFAA'
  }

}));

const MatterComments = () => {
  const classes = useStyles();

  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: matterComments } = useGetMatterCommentsForMeQuery();
  const [updateMatterComment] = useUpdateMatterCommentMutation();
  const [updateMatterManager] = useUpdateMatterManagerMutation();

  const [value, setValue] = useState<{ comment: string, matterNumber: string }>({ comment: "", matterNumber: "" });
  const [debouncedValue] = useDebounce(value, 150);
  const prevDebouncedValue = usePrevious(debouncedValue);

  const [message, setMessage] = useState<string | undefined>();
  const [updateWorkloadMessage] = useUpdateWorkloadMessageMutation();
  const [debouncedMessage] = useDebounce(message);
  const prevDebouncedMessage = usePrevious(debouncedMessage);


  useEffect(() => {
    if (message === undefined && currentUser) {
      setMessage(currentUser.message?.message ?? '');
    }
  }, [message, currentUser]);

  useEffect(() => {
    if (debouncedMessage !== undefined && prevDebouncedMessage !== undefined) {
      updateWorkloadMessage({
        workloadMessageCreationParams: {
          message: debouncedMessage,
        },
      });
    }
  }, [debouncedMessage]);

  useEffect(() => {
    if (debouncedValue !== undefined && prevDebouncedValue !== undefined) {
      updateMatterComment({
        body: {
          ...value,
          userId: currentUser?._id || ""
        },
      });
    }
  }, [debouncedValue]);

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableBody>
          <MatterRow title={"General comment"} subtitle={currentUser?.name} messageLength={message?.length} manager={false} currentUser={currentUser} defaultValue={currentUser?.message?.message} setValue={(value) => setMessage(value)} updateMatterManager={() => { }} />
          {matterComments?.length ? matterComments.map(matter => (
            <MatterRow title={matter.displayName} subtitle={matter.clientDisplayName} messageLength={0} currentUser={currentUser} manager={matter.manager == currentUser?._id} defaultValue={matter.comment} setValue={(value) => setValue({ matterNumber: matter.number || "", comment: value })} updateMatterManager={() => updateMatterManager({ body: { matterNumber: matter.number || '', userId: currentUser?._id || '', value: !matter.manager } })} />
          )) : (
            <TableRow>
              <TableCell colSpan={3}>No billable matters in the last 14 days found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const MatterRow = ({ title, subtitle, manager, defaultValue, setValue, updateMatterManager, currentUser, messageLength }) => {
  const classes = useStyles();

  return (<TableRow  >
    <TableCell colSpan={1} className={classes.matter__cell}>{title}
      {title != 'General comment' && organization(currentUser?.company).enableMatterPM && (<span onClick={() => updateMatterManager()} className={classes.matter__manager + " " + (manager ? classes.matter__manager_active : '')}>PM</span>)}
      <div style={{ opacity: 0.6, fontSize: 10, marginTop: -1 }}>{subtitle}</div></TableCell>
    <TableCell colSpan={2} className={classes.comment__cell}>
      <textarea
        maxLength={messageLength ? 110 : 85}
        defaultValue={defaultValue}
        className={classes.message__input + ' ' + ((messageLength > 80) ? classes.message__input_expanded : "")}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            //Prevent line breaks
            event.preventDefault();
          }
        }}

      />
    </TableCell>
  </TableRow>)
}

export default MatterComments;
