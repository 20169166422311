import theme from 'theme/theme';
import { useGetForecastsQuery, useGetUsersQuery } from 'utils/wemble-api';
import { ForecastDto } from 'utils/wemble-api.generated';

import moment, { Moment } from 'moment-timezone';
moment.tz.setDefault("utc");

export const formattedDate = (dateStr: string) => {

    const date = moment(dateStr);
    return ((date.locale('en').format('MMM D') == moment().locale('en').format('MMM D')) ? 'Today' : date.locale('en').format((date.diff(moment(), 'days') < 0 && date.diff(moment(), 'days') >= 0) ? 'ddd' : 'MMM D'))
}

export const formattedDateRange = (data) => {
    return formattedDate(data.startDate) + ((data.endDate && data.endDate !== data.startDate) ? ` - ${formattedDate(data.endDate)}` : '')
}

export function statusBackgroundColor(status: '0' | '1' | '2' | '3' | 'away' | string | undefined) {
    switch (status) {
        case '0':
            return theme.palette.blue.opacity.b16;
        case '1':
            return theme.palette.green.opacity.g16;
        case '2':
            return theme.palette.yellow.opacity.y16;
        case '3':
            return theme.palette.red.opacity.r16;
        default:
            return theme.palette.blueGray.opacity.bg16;
    }
}

export function statusColor(status: '0' | '1' | '2' | '3' | 'away' | string | undefined) {
    switch (status) {
        default:
            return '#828282';
        case '0':
            return theme.palette.blue.b2;
        case '1':
            return theme.palette.green.g4;
        case '2':
            return theme.palette.yellow.y3;
        case '3':
            return theme.palette.red.r1;
    }
}
export function statusTitle(status: '0' | '1' | '2' | '3' | 'away' | string | undefined) {

    switch (status) {
        case 'away':
            return 'Away';
        case '0':
            return 'Available';
        case '1':
            return 'Some availability';
        case '2':
            return 'Busy';
        case '3':
            return 'Seriously busy';
        default:
            return 'No data';

    }
}

interface Suggestion {
    title: string;
    range: {
        startDate: string;
        endDate: string;
    }
}

export const cleanedDateString = (date: any): string => moment(date).startOf('day').utcOffset(0, true).toISOString()
export const getToday = (): string => cleanedDateString(moment())

export const generateSuggestions = (nextDate: Moment | undefined, forecasts: ForecastDto[]): Suggestion[] => {
    if (!nextDate) return []
    const suggestions: Suggestion[] = [];
    const addSuggestion = (suggestion: Suggestion) => {
        if (suggestions.length >= 3) return
        if (suggestions.some(s => s.range.endDate == suggestion.range.endDate)) return
        if (forecasts.some(f => moment(f.startDate).isSameOrBefore(moment(suggestion.range.endDate)) && moment(f.endDate).isSameOrAfter(moment(suggestion.range.endDate)))) return
        suggestions.push(suggestion)
    }

    var nextWeekday = nextDate;

    if (![6, 0].includes(nextDate.locale('en').weekday())) addSuggestion({ title: "Next day", range: { startDate: cleanedDateString(nextDate), endDate: cleanedDateString(nextDate) } })
    else {
        nextWeekday = nextDate.clone().add(nextDate.locale('en').weekday() ? 2 : 1, 'days')
        addSuggestion({ title: "Next weekday", range: { startDate: cleanedDateString(nextWeekday), endDate: cleanedDateString(nextWeekday) } })
    }

    if ([1, 2, 3, 4].includes(nextDate.locale('en').weekday())) addSuggestion({ title: nextDate.locale('en').weekday() == 1 ? "Next week" : "Rest of week", range: { startDate: cleanedDateString(nextDate), endDate: cleanedDateString(nextDate.clone().add(5 - nextDate.locale('en').weekday(), 'days')) } })

    const nextForecast = forecasts.find(f => moment(f.startDate).isAfter(nextDate))
    const lastDate = moment(nextForecast?.startDate).add(-1, 'day')
    if (nextForecast && !lastDate.isSame(nextWeekday)) addSuggestion({ title: "Until " + formattedDate(cleanedDateString(lastDate)), range: { startDate: cleanedDateString(nextDate), endDate: cleanedDateString(lastDate) } })

    if (![6, 0].includes(nextDate.locale('en').weekday())) addSuggestion({ title: "Until next week", range: { startDate: cleanedDateString(nextDate), endDate: cleanedDateString(nextDate.clone().add(7, 'days').day(5)) } })
    else addSuggestion({ title: "Next week", range: { startDate: cleanedDateString(nextWeekday), endDate: cleanedDateString(nextDate.clone().add(7, 'days').day(5)) } })

    return suggestions
}

export const useGetStatusForUser = () => {
    const { data: users } = useGetUsersQuery();
    return (userId) => {
        const user = users!.find(u => userId == u._id)!
        return !user.onLeave ? user.currentWorkload?.toString() : 'away'
    }
}

export const useGetNextForecastForUser = () => {
    const { data: allForecasts } = useGetForecastsQuery();
    const getStatusForUser = useGetStatusForUser();

    return (userId) => {
        const status = getStatusForUser(userId);
        const forecasts = allForecasts?.filter((forecast) => forecast.user == userId && moment(forecast.endDate) >= moment(getToday())).sort((a, b) => new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime()) || []
        return forecasts.find(forecast => forecast.status != status)
    }
}

export const useGetCurrentForecastForUser = () => {
    const { data: allForecasts } = useGetForecastsQuery();
    const today = getToday()

    return (userId) => {
        const forecasts = allForecasts?.filter((forecast) => forecast.user == userId && moment(forecast.endDate) >= moment(getToday())).sort((a, b) => new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime()) || []
        return forecasts.find(forecast => moment(forecast.startDate) <= moment(today))
    }
}

export const convertUTCToLocalDate = (date: Date | null): Date | null => {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
}

export const convertLocalToUTCDate = (date: Date | null): Date | null => {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
}