import { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import home from './../../assets/icons/home.svg';
import office from './../../assets/icons/office.svg';
import officeSTO from './../../assets/icons/office-sto.svg';
import officeHEL from './../../assets/icons/office-hel.svg';
import absent from './../../assets/icons/absent.svg';
import elsewhere from './../../assets/icons/elsewhere.svg';
import court from './../../assets/icons/court.svg';
import none from './../../assets/icons/none.svg';
import classNames from 'classnames';
import moment from 'moment';
import { UserDto } from 'utils/wemble-api.generated';
import { useCreateWorkplaceForecastMutation } from 'utils/wemble-api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.gray.g7,
    maxHeight: 220,
    position: 'relative',
    height: '100%',
    borderRadius: 24,
    transition: '0.2s',
    overflow: 'visible',

  },
  firstFlexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 14px',
    },
    pointerEvents: 'auto',
    transition: 'margin-left 0.5s'





  },
  divider: {
    width: '34px',
    height: '100%',
    position: 'relative',
    cursor: 'pointer',
    pointerEvents: 'auto',
    transition: 'margin-left 0.5s',

  },
  divider__icon: {
    marginLeft: '8px',
    marginTop: '25px',
    transition: 'transform 0.25s'

  },
  secondFlexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'calc(100% - 24px)',
    padding: '12px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '6px 0',
    },
    pointerEvents: 'auto',
    transition: 'margin-left 0.5s'

  },
  firstContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderRadius: 24,
    overflow: 'hidden',
    pointerEvents: 'auto'



  },
  dividerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderRadius: 24,
    overflow: 'hidden',
    pointerEvents: 'auto'



  },

  secondContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderRadius: 24,
    overflow: 'hidden',
    pointerEvents: 'none'





  },
  container__item: {
    position: 'relative',
  },
  item: {
    width: 56,
    height: 56,
    [theme.breakpoints.down('md')]: {
      width: 42,
      minHeight: 42,
      height: 46,
    },
    overflow: 'hidden',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: '0.1s',
    position: 'relative',
    zIndex: 10,
  },
  item__option: {
    backgroundColor: theme.palette.blueGray.bg1,
    borderRadius: '50%',
    margin: '6px 0',
    width: 44,
    minHeight: 44,
    height: 44,
    [theme.breakpoints.down('md')]: {
      width: 40,
      minHeight: 40,
      height: 40,
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.1s',
    '& img': {
      width: '60%',
      filter: 'brightness(0) invert(1)',
      transition: '0.1s',
    },
    '&:hover': {
      backgroundColor: '#BDBDBD !important',
    },
  },
  item__optionDisabled: {
    '&:hover': {
      backgroundColor: `${theme.palette.state.disabled} !important`,
    },
  },
  item__day: {
    position: 'absolute',
    top: 52,
    width: '100%',
    textAlign: 'center',
    fontWeight: 600,

  },
  item__title: {
    position: 'absolute',
    top: -15,
    left: 6,
    width: '300%',
    fontWeight: 600,
    fontSize: 11,



  }
}));

type StatusType = { value: number | null; type: 'home' | 'office' | 'office-sto' | 'office-hel' | 'absent' | 'elsewhere' | 'none'; img: string };

const statusTypes: StatusType[] = [
  { value: 0, type: 'office', img: office },
  { value: 1, type: 'home', img: home },
  { value: 3, type: 'elsewhere', img: elsewhere },
  { value: 2, type: 'absent', img: absent },
  { value: null, type: 'none', img: none },

];

const statusTypesHS: StatusType[] = [
  { value: 0, type: 'office-sto', img: officeSTO },
  { value: 3, type: 'office-hel', img: officeHEL },
  { value: 1, type: 'home', img: home },
  { value: 2, type: 'absent', img: absent },

];
function returnDayOfWeek(dayNumber: number, currentDay: number, future: boolean) {
  if (!future && dayNumber === currentDay) {
    return 'TODAY';
  }
  switch (dayNumber) {
    case 0:
      return 'MON';
    case 1:
      return 'TUE';
    case 2:
      return 'WED';
    case 3:
      return 'THU';
    case 4:
      return 'FRI';
    default:
      return '';
  }
}
const DayItem = ({
  index,
  day,
  currentDay,
  value,
  disabled,
  enableElsewhere = false,
  dualOffices = false,
  future = false,
  enableInCourt = false,
  fetchUsers
}: {
  index: number;
  day: number;
  currentDay: number;
  value: number | null;
  disabled: boolean;
  enableElsewhere: boolean;
  dualOffices: boolean;
  future?: boolean;
  enableInCourt?: boolean;
  fetchUsers: () => Promise<void>
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [createWorkplaceForecast] = useCreateWorkplaceForecastMutation();
  const [hovered, setHovered] = useState(false);
  const [activeOption, setActiveOption] = useState<StatusType>();
  const [options, setOptions] = useState<StatusType[]>([]);

  useEffect(() => {
    setActiveOption((dualOffices ? statusTypesHS : statusTypes).find((obj) => obj.value === value));
    setOptions(dualOffices ? statusTypesHS : statusTypes.filter((obj) => obj.value !== value && (enableElsewhere || obj.type !== 'elsewhere') && (future || obj.type !== 'none')));
  }, [value]);

  function setForecast(value: number | null) {
    createWorkplaceForecast({
      index: index.toString(),
      workplaceForecastCreationParams: {
        option: value,
        future
      },
    }).then(_ => fetchUsers());
    setHovered(false);
  }

  return (
    <div
      className={classes.container__item}
      onMouseEnter={() => !disabled && setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ marginTop: future ? 12 : 0 }}
    >

      {future && !index && (<div className={classes.item__title}>
        DEFAULT WEEK
      </div>)}

      <div
        className={classes.item}
        style={hovered ? { height: 56 * (options.length + 1), backgroundColor: '#f2f2f2', borderRadius: '30px', boxShadow: "0px 8px 20px 0px #00000037" } : undefined}
      >


        <div
          className={classNames(classes.item__option, disabled && classes.item__optionDisabled)}
          style={
            disabled || value === null
              ? {
                backgroundColor: theme.palette.state.disabled,
                opacity: 0.7,
              }
              : activeOption?.type === 'absent'
                ? {
                  backgroundColor: theme.palette.state.disabled,
                  cursor: 'pointer',
                }
                : { cursor: 'pointer' }
          }
        >
          {value !== null && <img alt={'active-work-location'} src={(activeOption?.type == 'elsewhere' && enableInCourt) ? court : activeOption?.img} />}
        </div>
        {options?.map((option) => (
          <div
            key={'day-option-' + option.value}
            className={classes.item__option}
            onClick={(e) => {
              if (disabled) return;
              e.stopPropagation();
              setForecast(option.value);
              setHovered(false);
            }}
            style={(option.type === 'none')
              ? {
                backgroundColor: theme.palette.state.disabled,
                cursor: 'pointer',
              }
              : { cursor: 'pointer' }}
          >
            <img alt={'option-work-location'} src={(activeOption?.type == 'elsewhere' && enableInCourt) ? court : option.img} />
          </div>
        ))}
      </div>
      <div className={classes.item__day} style={{ opacity: disabled ? 0.5 : 1.0, fontSize: future ? 10 : 14, marginTop: future ? 2 : 6, color: future ? '#000000BB' : 'black' }}>
        {returnDayOfWeek(day, currentDay, future)}
      </div>
      {future}
    </div>

  );
};

const DaySelect = ({ user, disabled = false, fetchUsers }: { user: UserDto | null | undefined; disabled?: boolean, fetchUsers: () => Promise<void> }) => {
  const classes = useStyles();
  const [futureForecast, setFutureForecast] = useState(false);
  const [futureForecastDelayed, setFutureForecastDelayed] = useState(false);

  const currentDayInWeek = Math.min(moment().isoWeekday() - 1, 5);

  let week = [...Array(5)].map((_, i) => ({
    name: (currentDayInWeek + i) % 5,
    status: (user?.workplaceForecast || [])[i] ?? null
  }));


  let forecastWeek = [...Array(5)].map((_, i) => ({
    name: (i),
    status: (user?.workplaceDefault || [])[i] ?? null
  }));


  return (
    <div className={classes.root}>
      <div className={classes.firstContainer} style={{ overflow: futureForecastDelayed ? 'hidden' : (futureForecast ? 'hidden' : 'visible') }}>
        <div className={classes.firstFlexContainer} style={{ marginLeft: futureForecast ? '-100%' : '0%', width: (user?.company === "5e4d42f50fc8ec2139341a39") ? 'calc(100% - 24px)' : '100%' } /*Selmer*/}>

          {user
            ? week?.map((day, index) => (
              <DayItem
                key={day.name + '-day'}
                index={index}
                day={day.name}
                currentDay={currentDayInWeek}
                value={day.status}
                disabled={disabled}
                fetchUsers={fetchUsers}
                enableInCourt={user.company == "65f779d34b718376e412163f"}
                enableElsewhere={user.company === "63491b42fef920f504b85c93" || user.company == "65f779d34b718376e412163f"/*MAQS*/}
                dualOffices={false /* user.company === "5da0c1370b30f993a68d898f" SNELLMAN*/}

              />
            ))
            : null}
        </div>
      </div>

      <div className={classes.dividerContainer}>

        <div className={classes.divider} style={{ marginLeft: futureForecast ? 'calc(0%)' : 'calc(100% - 34px)', display: (user?.company === "5e4d42f50fc8ec2139341a39") ? 'inline' : 'none' } /*Selmer*/} onClick={() => { setFutureForecast(!futureForecast); setTimeout(() => setFutureForecastDelayed(!futureForecast), 500) }}>
          <svg className={classes.divider__icon} style={{ transform: futureForecast ? 'scaleX(-1)' : 'scaleX(1)' }} xmlns="http://www.w3.org/2000/svg" width="17" height="50" viewBox="0 0 17 50" fill="none">
            <path d="M4 4L12 26.9444L4 46" stroke="#BABABA" strokeWidth="8" strokeLinecap="round" />
          </svg>
        </div>
      </div>


      <div className={classes.secondContainer} style={{ overflow: futureForecastDelayed ? (futureForecast ? 'visible' : 'hidden') : 'hidden' }}>

        <div className={classes.secondFlexContainer} style={{ marginLeft: futureForecast ? 'calc(0% + 24px)' : '100%' }}>

          {user
            ? forecastWeek?.map((day, index) => (
              <DayItem
                key={day.name + '-day-future'}
                index={index}
                day={day.name}
                currentDay={currentDayInWeek}
                value={day.status}
                disabled={disabled}
                fetchUsers={fetchUsers}
                future={true}
                enableInCourt={user.company == "65f779d34b718376e412163f"}
                enableElsewhere={user.company === "63491b42fef920f504b85c93" || user.company == "65f779d34b718376e412163f"/*MAQS*/}
                dualOffices={false /*user.company === "5da0c1370b30f993a68d898f" SNELLMAN*/}
              />
            ))
            : null}
        </div>
      </div>



    </div>
  );
};

export default DaySelect;
