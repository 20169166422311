import { useCallback, useState } from 'react';
import { Button, makeStyles, Hidden, useMediaQuery } from '@material-ui/core';
import Calendar, { CalendarItemType } from './Calendar';
import RangeSelector from './RangeSelector';
import ItemModal from './ItemModal';

import moment from 'moment-timezone';
import { UserDto } from 'utils/wemble-api.generated';
import { useCreateForecastMutation, useCreateVacationMutation, useDeleteForecastMutation, useDeleteVacationMutation, useGetCurrentUserQuery, useGetForecastsQuery, useUpdateForecastMutation, useUpdateVacationMutation } from 'utils/wemble-api';
import { notEmpty } from 'utils/helpers';
import organization from 'utils/organization';
import Modal from 'components/shared/Modal';
import { cleanedDateString, getToday, statusTitle } from 'components/Home/Forecast/utils';

const useStyles = makeStyles((theme) => ({
  user_button: {
    position: 'absolute',
    width: '150px',
    height: '40px',
    left: '36px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      top: '52px',
      left: '16px',
      width: '120px',
      height: '30px',
    },
  },
}));

const VacationPlanner = ({
  isOpen,
  close,
  selectedUser,
  users,
}: {
  isOpen: boolean;
  close: () => void;
  selectedUser?: string | null | undefined;
  users: UserDto[] | undefined;
}) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(moment(getToday()));
  const [itemModalUserId, setItemModalUserId] = useState<string>();
  const [addItemModal, setAddItemModal] = useState(false);
  const [editItemModal, setEditItemModal] = useState<false | CalendarItemType>(false);

  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: allForecasts } = useGetForecastsQuery(undefined, { refetchOnReconnect: true });

  const [createForecast, { isLoading: creatingForecast }] = useCreateForecastMutation();
  const [updateForecast, { isLoading: updatingForecast }] = useUpdateForecastMutation();
  const [deleteForecast, { isLoading: deletingForecast }] = useDeleteForecastMutation();

  const disabled = creatingForecast || updatingForecast || deletingForecast;

  const newVacation = useCallback(
    async (title: string, startDate: Date | null, endDate: Date | null) => {
      if (!startDate || !itemModalUserId) return;

      await createForecast({
        userId: itemModalUserId,
        forecastUpsertParams: {
          comment: title,
          startDate: cleanedDateString(startDate),
          endDate: cleanedDateString(endDate) || '',
          status: 'away'
        },
      });
      setAddItemModal(false);
    },
    [createForecast, itemModalUserId],
  );

  const editVacation = useCallback(
    async (title: string, startDate: Date | null, endDate: Date | null, vacationId: string | null | undefined) => {
      if (!vacationId || !itemModalUserId || !startDate) return;
      await updateForecast({
        forecastId: vacationId,
        userId: itemModalUserId,
        forecastUpsertParams: {
          comment: title,
          startDate: cleanedDateString(startDate),
          endDate: cleanedDateString(endDate) || '',
          status: 'away'
        },
      });
      setEditItemModal(false);
    },
    [updateForecast, itemModalUserId],
  );

  const removeVacation = useCallback(
    async (vacationId: string | null | undefined) => {
      if (!vacationId || !itemModalUserId) return;
      await deleteForecast({ forecastId: vacationId, userId: itemModalUserId });
      setEditItemModal(false);
    },
    [deleteForecast, itemModalUserId],
  );

  const handleCloseItemModal = useCallback(() => {
    setAddItemModal(false);
    setEditItemModal(false);
    setItemModalUserId(undefined);
  }, []);

  const handleOpenEditModal = (item: CalendarItemType, userId: string) => {
    setEditItemModal(item);
    setItemModalUserId(userId);
  };

  const handleOpenAddModal = (userId: string) => {
    setAddItemModal(true);
    setItemModalUserId(userId);
  };

  const isAdmin = Boolean(currentUser?.admin || currentUser?.administratorPriviliges || currentUser?.companyAdmin);
  const viewOnly = organization(currentUser?.company).absencePlannerviewOnly || false;

  var length = 14;
  if (useMediaQuery('(min-width:960px)')) length += 5
  if (useMediaQuery('(min-width:1000px)')) length += 4
  if (useMediaQuery('(min-width:1100px)')) length += 4
  if (useMediaQuery('(min-width:1200px)')) length += 3
  if (useMediaQuery('(min-width:1300px)')) length += 2
  if (useMediaQuery('(min-width:1400px)')) length += 2
  if (useMediaQuery('(min-width:1500px)')) length += 2
  if (useMediaQuery('(min-width:1600px)')) length += 2
  if (useMediaQuery('(min-width:1700px)')) length += 2



  return (
    <div>
      <ItemModal
        isOpen={Boolean(addItemModal) || Boolean(editItemModal)}
        item={editItemModal}
        handleAddVacation={newVacation}
        handleEditVacation={editVacation}
        handleRemoveVacation={removeVacation}
        disabled={disabled}
        close={handleCloseItemModal}
      />
      <Modal customWidth={1500} visible={isOpen} close={() => !disabled && close()}>
        <div style={{ margin: '-14px' }}>
          <RangeSelector startDate={startDate} length={length} setStartDate={setStartDate} />

          <br />

          {!isAdmin && !viewOnly && (
            <Button
              className={classes.user_button}
              variant="outlined"
              color="primary"
              onClick={() => currentUser?._id && handleOpenAddModal(currentUser._id)}
              disabled={disabled}
            >
              Add item
            </Button>
          )}

          <Hidden smUp>
            <br />
          </Hidden>

          <Calendar
            startDate={startDate}
            length={length}
            editItem={handleOpenEditModal}
            openAddModal={handleOpenAddModal}
            isAdmin={isAdmin}
            viewOnly={viewOnly}
            users={users
              ?.filter((user) => user.completed && !user.admin)
              .map((user) => ({
                isCurrentUser: user._id === currentUser?._id,
                id: user._id,
                name: user.name,
                currentWorkload: user.currentWorkload,
                title: user.title,
                profilePicture: user.profilePicture,
                items:
                  (allForecasts?.filter((forecast) => forecast.user == user?._id).sort((a, b) => new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime()) || [])
                    ?.map((forecast) =>
                    ({
                      title: forecast.comment || statusTitle(forecast.status),
                      startDate: moment(forecast.startDate).utc(),
                      endDate: moment(forecast.endDate).utc(),
                      status: forecast.status,
                      _id: forecast._id
                    })

                    )
              })
              )
              /*.sort((a, b) => (b.items.filter(item => moment(item.endDate) >= moment()).length - a.items.filter(item => moment(item.endDate) >= moment()).length))*/.sort((a, b) => ((a.isCurrentUser) ? -1 : (b.isCurrentUser) ? 1 : 0)).sort((a, b) => ((a.id == selectedUser) ? -1 : (b.id == selectedUser) ? 1 : 0))}
          />
        </div>
      </Modal>

    </div >
  );
};

export default VacationPlanner;
