import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./axiosBaseQuery";
export const api = createApi({
  baseQuery: axiosBaseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    refreshToken: build.query<RefreshTokenApiResponse, RefreshTokenApiArg>({
      query: () => ({ url: `/account/token/new` }),
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/account/login`,
        method: "POST",
        body: queryArg.loginParams,
      }),
    }),
    registerUser: build.mutation<RegisterUserApiResponse, RegisterUserApiArg>({
      query: (queryArg) => ({
        url: `/account/${queryArg.id}/register`,
        method: "POST",
        body: queryArg.registerParams,
      }),
    }),
    changePassword: build.mutation<
      ChangePasswordApiResponse,
      ChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/account/password`,
        method: "POST",
        body: queryArg.changePasswordParams,
      }),
    }),
    setPassword: build.mutation<SetPasswordApiResponse, SetPasswordApiArg>({
      query: (queryArg) => ({
        url: `/account/set-password/${queryArg.resetHash}`,
        method: "POST",
        body: queryArg.setPasswordParams,
      }),
    }),
    resetPassword: build.mutation<
      ResetPasswordApiResponse,
      ResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/account/reset-password`,
        method: "POST",
        body: queryArg.resetPasswordParams,
      }),
    }),
    resendUserInvite: build.mutation<
      ResendUserInviteApiResponse,
      ResendUserInviteApiArg
    >({
      query: (queryArg) => ({
        url: `/account/${queryArg.id}/resend-invite`,
        method: "POST",
      }),
    }),
    updateAzureTenant: build.mutation<
      UpdateAzureTenantApiResponse,
      UpdateAzureTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/azure-ad/tenant`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    removeAzureTenant: build.mutation<
      RemoveAzureTenantApiResponse,
      RemoveAzureTenantApiArg
    >({
      query: () => ({ url: `/azure-ad/tenant`, method: "DELETE" }),
    }),
    signInAzureUserWithCode: build.mutation<
      SignInAzureUserWithCodeApiResponse,
      SignInAzureUserWithCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/azure-ad/sign-in-with-code`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    signInAzureUserWithToken: build.mutation<
      SignInAzureUserWithTokenApiResponse,
      SignInAzureUserWithTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/azure-ad/sign-in-with-token`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getAzureUsers: build.query<GetAzureUsersApiResponse, GetAzureUsersApiArg>({
      query: () => ({ url: `/azure-ad/users` }),
    }),
    getCompany: build.query<GetCompanyApiResponse, GetCompanyApiArg>({
      query: () => ({ url: `/company` }),
    }),
    updateCompany: build.mutation<
      UpdateCompanyApiResponse,
      UpdateCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/company`,
        method: "PUT",
        body: queryArg.companyUpdateParams,
      }),
    }),
    getSsoAssertion: build.mutation<
      GetSsoAssertionApiResponse,
      GetSsoAssertionApiArg
    >({
      query: (queryArg) => ({
        url: `/company/${queryArg.id}/sso`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getSsoLogin: build.query<GetSsoLoginApiResponse, GetSsoLoginApiArg>({
      query: (queryArg) => ({ url: `/company/${queryArg.id}/sso/login` }),
    }),
    testCompanySmtpConfig: build.mutation<
      TestCompanySmtpConfigApiResponse,
      TestCompanySmtpConfigApiArg
    >({
      query: () => ({ url: `/company/test-smtp-config`, method: "PUT" }),
    }),
    updateCompanySmtpConfig: build.mutation<
      UpdateCompanySmtpConfigApiResponse,
      UpdateCompanySmtpConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/company/smtp-config`,
        method: "PUT",
        body: queryArg.companySmtpConfigParams,
      }),
    }),
    updateCompanySsoConfig: build.mutation<
      UpdateCompanySsoConfigApiResponse,
      UpdateCompanySsoConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/company/sso-config`,
        method: "PUT",
        body: queryArg.companySsoConfigParams,
      }),
    }),
    getCompetences: build.query<
      GetCompetencesApiResponse,
      GetCompetencesApiArg
    >({
      query: () => ({ url: `/competences` }),
    }),
    createCompetence: build.mutation<
      CreateCompetenceApiResponse,
      CreateCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/competences`,
        method: "POST",
        body: queryArg.competenceCreationParams,
        params: { addToUser: queryArg.addToUser },
      }),
    }),
    getCompetence: build.query<GetCompetenceApiResponse, GetCompetenceApiArg>({
      query: (queryArg) => ({ url: `/competences/${queryArg.id}` }),
    }),
    updateCompetence: build.mutation<
      UpdateCompetenceApiResponse,
      UpdateCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/competences/${queryArg.id}`,
        method: "PUT",
        body: queryArg.competenceUpdateParams,
      }),
    }),
    deleteCompetence: build.mutation<
      DeleteCompetenceApiResponse,
      DeleteCompetenceApiArg
    >({
      query: (queryArg) => ({
        url: `/competences/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    addCompetenceToUsers: build.mutation<
      AddCompetenceToUsersApiResponse,
      AddCompetenceToUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/competences/${queryArg.id}/users`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    addCompetenceToUser: build.mutation<
      AddCompetenceToUserApiResponse,
      AddCompetenceToUserApiArg
    >({
      query: (queryArg) => ({
        url: `/competences/${queryArg.id}/user/${queryArg.userId}`,
        method: "POST",
      }),
    }),
    updateTimekeeper: build.mutation<
      UpdateTimekeeperApiResponse,
      UpdateTimekeeperApiArg
    >({
      query: (queryArg) => ({
        url: `/elite-3e/timekeepers/${queryArg.companyId}/${queryArg.timekeeperNumber}/${queryArg.key}`,
        method: "PUT",
        body: queryArg.timekeeperDto,
      }),
    }),
    getUtilizationReport: build.mutation<
      GetUtilizationReportApiResponse,
      GetUtilizationReportApiArg
    >({
      query: (queryArg) => ({
        url: `/elite-3e/utilization-report`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getDetailedReport: build.mutation<
      GetDetailedReportApiResponse,
      GetDetailedReportApiArg
    >({
      query: (queryArg) => ({
        url: `/elite-3e/detailed-report`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getForecasts: build.query<GetForecastsApiResponse, GetForecastsApiArg>({
      query: () => ({ url: `/forecast` }),
    }),
    createForecast: build.mutation<
      CreateForecastApiResponse,
      CreateForecastApiArg
    >({
      query: (queryArg) => ({
        url: `/forecast/${queryArg.userId}`,
        method: "POST",
        body: queryArg.forecastUpsertParams,
      }),
    }),
    updateForecast: build.mutation<
      UpdateForecastApiResponse,
      UpdateForecastApiArg
    >({
      query: (queryArg) => ({
        url: `/forecast/${queryArg.userId}/${queryArg.forecastId}`,
        method: "PUT",
        body: queryArg.forecastUpsertParams,
      }),
    }),
    deleteForecast: build.mutation<
      DeleteForecastApiResponse,
      DeleteForecastApiArg
    >({
      query: (queryArg) => ({
        url: `/forecast/${queryArg.userId}/${queryArg.forecastId}`,
        method: "DELETE",
      }),
    }),
    getGroups: build.query<GetGroupsApiResponse, GetGroupsApiArg>({
      query: () => ({ url: `/group` }),
    }),
    createGroup: build.mutation<CreateGroupApiResponse, CreateGroupApiArg>({
      query: (queryArg) => ({
        url: `/group`,
        method: "POST",
        body: queryArg.groupUpsertParams,
      }),
    }),
    getGroupsForUser: build.query<
      GetGroupsForUserApiResponse,
      GetGroupsForUserApiArg
    >({
      query: (queryArg) => ({ url: `/group/${queryArg.userId}` }),
    }),
    updateGroup: build.mutation<UpdateGroupApiResponse, UpdateGroupApiArg>({
      query: (queryArg) => ({
        url: `/group/${queryArg.id}`,
        method: "PUT",
        body: queryArg.groupUpsertParams,
      }),
    }),
    deleteGroup: build.mutation<DeleteGroupApiResponse, DeleteGroupApiArg>({
      query: (queryArg) => ({ url: `/group/${queryArg.id}`, method: "DELETE" }),
    }),
    getLevelOfSkills: build.query<
      GetLevelOfSkillsApiResponse,
      GetLevelOfSkillsApiArg
    >({
      query: () => ({ url: `/level-of-skill` }),
    }),
    createLevelOfSkill: build.mutation<
      CreateLevelOfSkillApiResponse,
      CreateLevelOfSkillApiArg
    >({
      query: (queryArg) => ({
        url: `/level-of-skill`,
        method: "POST",
        body: queryArg.levelOfSkillUpsertParams,
      }),
    }),
    updateLevelOfSkill: build.mutation<
      UpdateLevelOfSkillApiResponse,
      UpdateLevelOfSkillApiArg
    >({
      query: (queryArg) => ({
        url: `/level-of-skill/${queryArg.id}`,
        method: "PUT",
        body: queryArg.levelOfSkillUpsertParams,
      }),
    }),
    deleteLevelOfSkill: build.mutation<
      DeleteLevelOfSkillApiResponse,
      DeleteLevelOfSkillApiArg
    >({
      query: (queryArg) => ({
        url: `/level-of-skill/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getMatterCommentsForUser: build.query<
      GetMatterCommentsForUserApiResponse,
      GetMatterCommentsForUserApiArg
    >({
      query: (queryArg) => ({ url: `/matter/comment/${queryArg.userId}` }),
    }),
    getMatterCommentsForMe: build.query<
      GetMatterCommentsForMeApiResponse,
      GetMatterCommentsForMeApiArg
    >({
      query: () => ({ url: `/matter/comment` }),
    }),
    updateMatterComment: build.mutation<
      UpdateMatterCommentApiResponse,
      UpdateMatterCommentApiArg
    >({
      query: (queryArg) => ({
        url: `/matter/comment`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    updateMatterManager: build.mutation<
      UpdateMatterManagerApiResponse,
      UpdateMatterManagerApiArg
    >({
      query: (queryArg) => ({
        url: `/matter/manager`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getNotificationTokens: build.query<
      GetNotificationTokensApiResponse,
      GetNotificationTokensApiArg
    >({
      query: () => ({ url: `/notification-token` }),
    }),
    createNotificationToken: build.mutation<
      CreateNotificationTokenApiResponse,
      CreateNotificationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/notification-token/${queryArg.tokenType}`,
        method: "POST",
        body: queryArg.notificationTokenCreationParams,
      }),
    }),
    deleteNotificationToken: build.mutation<
      DeleteNotificationTokenApiResponse,
      DeleteNotificationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/notification-token/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getOffices: build.query<GetOfficesApiResponse, GetOfficesApiArg>({
      query: () => ({ url: `/office` }),
    }),
    createOffice: build.mutation<CreateOfficeApiResponse, CreateOfficeApiArg>({
      query: (queryArg) => ({
        url: `/office`,
        method: "POST",
        body: queryArg.officeCreationParams,
      }),
    }),
    getOfficesForUser: build.query<
      GetOfficesForUserApiResponse,
      GetOfficesForUserApiArg
    >({
      query: (queryArg) => ({ url: `/office/${queryArg.userId}` }),
    }),
    updateOffice: build.mutation<UpdateOfficeApiResponse, UpdateOfficeApiArg>({
      query: (queryArg) => ({
        url: `/office/${queryArg.id}`,
        method: "PUT",
        body: queryArg.officeUpdateParams,
      }),
    }),
    deleteOffice: build.mutation<DeleteOfficeApiResponse, DeleteOfficeApiArg>({
      query: (queryArg) => ({
        url: `/office/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getProjectCategories: build.query<
      GetProjectCategoriesApiResponse,
      GetProjectCategoriesApiArg
    >({
      query: () => ({ url: `/project-category` }),
    }),
    createProjectCategory: build.mutation<
      CreateProjectCategoryApiResponse,
      CreateProjectCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/project-category`,
        method: "POST",
        body: queryArg.projectCategoryUpsertParams,
      }),
    }),
    updateProjectCategory: build.mutation<
      UpdateProjectCategoryApiResponse,
      UpdateProjectCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/project-category/${queryArg.id}`,
        method: "PUT",
        body: queryArg.projectCategoryUpsertParams,
      }),
    }),
    deleteProjectCategory: build.mutation<
      DeleteProjectCategoryApiResponse,
      DeleteProjectCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/project-category/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getProjectInvoices: build.query<
      GetProjectInvoicesApiResponse,
      GetProjectInvoicesApiArg
    >({
      query: (queryArg) => ({ url: `/project-invoice/${queryArg.projectId}` }),
    }),
    createProjectInvoice: build.mutation<
      CreateProjectInvoiceApiResponse,
      CreateProjectInvoiceApiArg
    >({
      query: (queryArg) => ({
        url: `/project-invoice/${queryArg.projectId}`,
        method: "POST",
        body: queryArg.projectInvoiceInsertParams,
      }),
    }),
    updateProjectInvoice: build.mutation<
      UpdateProjectInvoiceApiResponse,
      UpdateProjectInvoiceApiArg
    >({
      query: (queryArg) => ({
        url: `/project-invoice/${queryArg.projectId}/${queryArg.id}`,
        method: "PUT",
        body: queryArg.projectInvoiceUpdateParams,
      }),
    }),
    deleteProjectInvoice: build.mutation<
      DeleteProjectInvoiceApiResponse,
      DeleteProjectInvoiceApiArg
    >({
      query: (queryArg) => ({
        url: `/project-invoice/${queryArg.projectId}/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getProjectStages: build.query<
      GetProjectStagesApiResponse,
      GetProjectStagesApiArg
    >({
      query: () => ({ url: `/project-stage` }),
    }),
    createProjectStage: build.mutation<
      CreateProjectStageApiResponse,
      CreateProjectStageApiArg
    >({
      query: (queryArg) => ({
        url: `/project-stage`,
        method: "POST",
        body: queryArg.projectStageUpsertParams,
      }),
    }),
    updateProjectStage: build.mutation<
      UpdateProjectStageApiResponse,
      UpdateProjectStageApiArg
    >({
      query: (queryArg) => ({
        url: `/project-stage/${queryArg.id}`,
        method: "PUT",
        body: queryArg.projectStageUpsertParams,
      }),
    }),
    deleteProjectStage: build.mutation<
      DeleteProjectStageApiResponse,
      DeleteProjectStageApiArg
    >({
      query: (queryArg) => ({
        url: `/project-stage/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getProjectTimeReports: build.query<
      GetProjectTimeReportsApiResponse,
      GetProjectTimeReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/project-time-report/${queryArg.projectId}`,
      }),
    }),
    createProjectTimeReport: build.mutation<
      CreateProjectTimeReportApiResponse,
      CreateProjectTimeReportApiArg
    >({
      query: (queryArg) => ({
        url: `/project-time-report/${queryArg.projectId}`,
        method: "POST",
        body: queryArg.projectTimeReportInsertParams,
      }),
    }),
    updateProjectTimeReport: build.mutation<
      UpdateProjectTimeReportApiResponse,
      UpdateProjectTimeReportApiArg
    >({
      query: (queryArg) => ({
        url: `/project-time-report/${queryArg.projectId}/${queryArg.id}`,
        method: "PUT",
        body: queryArg.projectTimeReportUpdateParams,
      }),
    }),
    deleteProjectTimeReport: build.mutation<
      DeleteProjectTimeReportApiResponse,
      DeleteProjectTimeReportApiArg
    >({
      query: (queryArg) => ({
        url: `/project-time-report/${queryArg.projectId}/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getProjects: build.query<GetProjectsApiResponse, GetProjectsApiArg>({
      query: (queryArg) => ({
        url: `/project`,
        params: { all: queryArg.all, office: queryArg.office },
      }),
    }),
    createProject: build.mutation<
      CreateProjectApiResponse,
      CreateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/project`,
        method: "POST",
        body: queryArg.projectCreationParams,
      }),
    }),
    getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
      query: (queryArg) => ({ url: `/project/${queryArg.id}` }),
    }),
    updateProject: build.mutation<
      UpdateProjectApiResponse,
      UpdateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.id}`,
        method: "PUT",
        body: queryArg.projectUpdateParams,
      }),
    }),
    deleteProject: build.mutation<
      DeleteProjectApiResponse,
      DeleteProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getFilteredProjects: build.mutation<
      GetFilteredProjectsApiResponse,
      GetFilteredProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/project/filtered`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    archiveProject: build.mutation<
      ArchiveProjectApiResponse,
      ArchiveProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.id}/archive`,
        method: "PUT",
      }),
    }),
    unarchiveProject: build.mutation<
      UnarchiveProjectApiResponse,
      UnarchiveProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.id}/unarchive`,
        method: "PUT",
      }),
    }),
    addProjectToUser: build.mutation<
      AddProjectToUserApiResponse,
      AddProjectToUserApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.id}/user/${queryArg.userId}`,
        method: "POST",
      }),
    }),
    deleteProjectFromUser: build.mutation<
      DeleteProjectFromUserApiResponse,
      DeleteProjectFromUserApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.id}/user/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    addProjectToUsers: build.mutation<
      AddProjectToUsersApiResponse,
      AddProjectToUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.id}/users`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    createSkill: build.mutation<CreateSkillApiResponse, CreateSkillApiArg>({
      query: (queryArg) => ({
        url: `/skill`,
        method: "POST",
        body: queryArg.skillCreationParams,
      }),
    }),
    updateSkill: build.mutation<UpdateSkillApiResponse, UpdateSkillApiArg>({
      query: (queryArg) => ({
        url: `/skill/${queryArg.id}`,
        method: "PUT",
        body: queryArg.skillUpdateParams,
      }),
    }),
    deleteSkill: build.mutation<DeleteSkillApiResponse, DeleteSkillApiArg>({
      query: (queryArg) => ({ url: `/skill/${queryArg.id}`, method: "DELETE" }),
    }),
    getStatistics: build.mutation<
      GetStatisticsApiResponse,
      GetStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/statistic`,
        method: "POST",
        body: queryArg.body,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    getCurrentUser: build.query<
      GetCurrentUserApiResponse,
      GetCurrentUserApiArg
    >({
      query: () => ({ url: `/users/me` }),
    }),
    getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
      query: () => ({ url: `/users` }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: `/users`,
        method: "POST",
        body: queryArg.userCreationParams,
      }),
    }),
    getFilteredUsers: build.mutation<
      GetFilteredUsersApiResponse,
      GetFilteredUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/users/filtered`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getFilteredUserIds: build.mutation<
      GetFilteredUserIdsApiResponse,
      GetFilteredUserIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/filteredIds`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUserWorkloadHistory: build.query<
      GetUserWorkloadHistoryApiResponse,
      GetUserWorkloadHistoryApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.id}/workload-history` }),
    }),
    getUserById: build.query<GetUserByIdApiResponse, GetUserByIdApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.id}` }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}`,
        method: "PUT",
        body: queryArg.userUpdateParams,
      }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.id}`, method: "DELETE" }),
    }),
    getUncompletedUserById: build.query<
      GetUncompletedUserByIdApiResponse,
      GetUncompletedUserByIdApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.id}/uncompleted` }),
    }),
    createMultipleUsers: build.mutation<
      CreateMultipleUsersApiResponse,
      CreateMultipleUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/users/multiple`,
        method: "POST",
        body: queryArg.multipleUserCreationParams,
      }),
    }),
    updateUserProfilePicture: build.mutation<
      UpdateUserProfilePictureApiResponse,
      UpdateUserProfilePictureApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/profile-picture`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    changeUserTeam: build.mutation<
      ChangeUserTeamApiResponse,
      ChangeUserTeamApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/move`,
        method: "PUT",
        body: queryArg.changeUserTeamParams,
      }),
    }),
    updateUserAdminStatus: build.mutation<
      UpdateUserAdminStatusApiResponse,
      UpdateUserAdminStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/admin`,
        method: "PUT",
        body: queryArg.updateUserAdminStatusParams,
      }),
    }),
    createVacation: build.mutation<
      CreateVacationApiResponse,
      CreateVacationApiArg
    >({
      query: (queryArg) => ({
        url: `/vacation/${queryArg.userId}`,
        method: "POST",
        body: queryArg.vacationUpsertParams,
      }),
    }),
    updateVacation: build.mutation<
      UpdateVacationApiResponse,
      UpdateVacationApiArg
    >({
      query: (queryArg) => ({
        url: `/vacation/${queryArg.userId}/${queryArg.vacationId}`,
        method: "PUT",
        body: queryArg.vacationUpsertParams,
      }),
    }),
    deleteVacation: build.mutation<
      DeleteVacationApiResponse,
      DeleteVacationApiArg
    >({
      query: (queryArg) => ({
        url: `/vacation/${queryArg.userId}/${queryArg.vacationId}`,
        method: "DELETE",
      }),
    }),
    getFilteredWorkloadHistory: build.query<
      GetFilteredWorkloadHistoryApiResponse,
      GetFilteredWorkloadHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/workload-history/${queryArg.scope}/${queryArg.id}/${queryArg.days}`,
      }),
    }),
    updateWorkloadMessage: build.mutation<
      UpdateWorkloadMessageApiResponse,
      UpdateWorkloadMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/workload/message`,
        method: "PUT",
        body: queryArg.workloadMessageCreationParams,
      }),
    }),
    refreshUser: build.mutation<RefreshUserApiResponse, RefreshUserApiArg>({
      query: () => ({ url: `/workload/refresh`, method: "PUT" }),
    }),
    createWorkload: build.mutation<
      CreateWorkloadApiResponse,
      CreateWorkloadApiArg
    >({
      query: (queryArg) => ({
        url: `/workload/${queryArg["type"]}`,
        method: "POST",
        body: queryArg.workloadCreationParams,
      }),
    }),
    createWorkplaceForecast: build.mutation<
      CreateWorkplaceForecastApiResponse,
      CreateWorkplaceForecastApiArg
    >({
      query: (queryArg) => ({
        url: `/workplace-forecast/${queryArg.index}`,
        method: "POST",
        body: queryArg.workplaceForecastCreationParams,
      }),
    }),
    getWorkplaceHistory: build.query<
      GetWorkplaceHistoryApiResponse,
      GetWorkplaceHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/workplace-history/${queryArg.scope}/${queryArg.id}/${queryArg.days}`,
      }),
    }),
  }),
});
export type RefreshTokenApiResponse = /** status 200 Ok */ string;
export type RefreshTokenApiArg = void;
export type LoginApiResponse = /** status 200 Ok */ {
  token: string;
};
export type LoginApiArg = {
  loginParams: LoginParams;
};
export type RegisterUserApiResponse = /** status 200 Ok */ {
  token: string;
};
export type RegisterUserApiArg = {
  id: string;
  registerParams: RegisterParams;
};
export type ChangePasswordApiResponse = /** status 200 Ok */ {
  success: boolean;
};
export type ChangePasswordApiArg = {
  changePasswordParams: ChangePasswordParams;
};
export type SetPasswordApiResponse = /** status 200 Ok */ {
  token: string;
};
export type SetPasswordApiArg = {
  resetHash: string;
  setPasswordParams: SetPasswordParams;
};
export type ResetPasswordApiResponse = /** status 200 Ok */ {
  success: boolean;
};
export type ResetPasswordApiArg = {
  resetPasswordParams: ResetPasswordParams;
};
export type ResendUserInviteApiResponse = /** status 200 Ok */ UserDto;
export type ResendUserInviteApiArg = {
  id: string;
};
export type UpdateAzureTenantApiResponse = /** status 200 Ok */ CompanyDto;
export type UpdateAzureTenantApiArg = {
  body: {
    tenantId: string;
  };
};
export type RemoveAzureTenantApiResponse = /** status 200 Ok */ CompanyDto;
export type RemoveAzureTenantApiArg = void;
export type SignInAzureUserWithCodeApiResponse = /** status 200 Ok */ {
  token: string;
  redirect?: string;
};
export type SignInAzureUserWithCodeApiArg = {
  body: {
    redirectUri: string;
    code: string;
  };
};
export type SignInAzureUserWithTokenApiResponse = /** status 200 Ok */ {
  token: string;
  redirect?: string;
};
export type SignInAzureUserWithTokenApiArg = {
  body: {
    adUserId: string;
    tenantId: string;
    adToken: string;
  };
};
export type GetAzureUsersApiResponse = /** status 200 Ok */ AzureUser[];
export type GetAzureUsersApiArg = void;
export type GetCompanyApiResponse = /** status 200 Ok */ CompanyDto;
export type GetCompanyApiArg = void;
export type UpdateCompanyApiResponse = /** status 200 Ok */ CompanyDto;
export type UpdateCompanyApiArg = {
  companyUpdateParams: CompanyUpdateParams;
};
export type GetSsoAssertionApiResponse = /** status 200 Ok */ any;
export type GetSsoAssertionApiArg = {
  id: string;
  body: any;
};
export type GetSsoLoginApiResponse = /** status 200 Ok */ any;
export type GetSsoLoginApiArg = {
  id: string;
};
export type TestCompanySmtpConfigApiResponse = /** status 200 Ok */ string;
export type TestCompanySmtpConfigApiArg = void;
export type UpdateCompanySmtpConfigApiResponse =
  /** status 200 Ok */ CompanyDto;
export type UpdateCompanySmtpConfigApiArg = {
  companySmtpConfigParams: CompanySmtpConfigParams;
};
export type UpdateCompanySsoConfigApiResponse = /** status 200 Ok */ CompanyDto;
export type UpdateCompanySsoConfigApiArg = {
  companySsoConfigParams: CompanySsoConfigParams;
};
export type GetCompetencesApiResponse = /** status 200 Ok */
  | CompetenceDto[]
  | null;
export type GetCompetencesApiArg = void;
export type CreateCompetenceApiResponse =
  /** status 200 Ok */ CompetenceDto | null;
export type CreateCompetenceApiArg = {
  addToUser: string;
  competenceCreationParams: CompetenceCreationParams;
};
export type GetCompetenceApiResponse =
  /** status 200 Ok */ CompetenceDto | null;
export type GetCompetenceApiArg = {
  id: string;
};
export type UpdateCompetenceApiResponse =
  /** status 200 Ok */ CompetenceDto | null;
export type UpdateCompetenceApiArg = {
  id: string;
  competenceUpdateParams: CompetenceUpdateParams;
};
export type DeleteCompetenceApiResponse = /** status 200 Ok */ boolean;
export type DeleteCompetenceApiArg = {
  id: string;
};
export type AddCompetenceToUsersApiResponse = /** status 200 Ok */ boolean;
export type AddCompetenceToUsersApiArg = {
  id: string;
  body: {
    userIds: string[];
  };
};
export type AddCompetenceToUserApiResponse = /** status 200 Ok */ boolean;
export type AddCompetenceToUserApiArg = {
  id: string;
  userId: string;
};
export type UpdateTimekeeperApiResponse = /** status 200 Ok */ boolean;
export type UpdateTimekeeperApiArg = {
  companyId: string;
  timekeeperNumber: string;
  key: string;
  timekeeperDto: TimekeeperDto;
};
export type GetUtilizationReportApiResponse =
  /** status 200 Ok */ UtilizationReport;
export type GetUtilizationReportApiArg = {
  body: {
    filters: UserFilterParams;
  };
};
export type GetDetailedReportApiResponse = /** status 200 Ok */ DetailedReport;
export type GetDetailedReportApiArg = {
  body: {
    filters: UserFilterParams;
  };
};
export type GetForecastsApiResponse = /** status 200 Ok */ ForecastDto[] | null;
export type GetForecastsApiArg = void;
export type CreateForecastApiResponse = /** status 200 Ok */ ForecastDto;
export type CreateForecastApiArg = {
  userId: string;
  forecastUpsertParams: ForecastUpsertParams;
};
export type UpdateForecastApiResponse = /** status 200 Ok */ ForecastDto;
export type UpdateForecastApiArg = {
  userId: string;
  forecastId: string;
  forecastUpsertParams: ForecastUpsertParams;
};
export type DeleteForecastApiResponse = /** status 200 Ok */ "true";
export type DeleteForecastApiArg = {
  userId: string;
  forecastId: string;
};
export type GetGroupsApiResponse = /** status 200 Ok */ {
  allowGroupSelection: boolean;
  customHtml?: string;
  companyName?: string | null;
  groups: GroupDto[];
};
export type GetGroupsApiArg = void;
export type CreateGroupApiResponse = /** status 200 Ok */ GroupDto;
export type CreateGroupApiArg = {
  groupUpsertParams: GroupUpsertParams;
};
export type GetGroupsForUserApiResponse = /** status 200 Ok */ {
  allowGroupSelection: boolean;
  customHtml?: string;
  companyName?: string | null;
  groups: GroupDto[];
};
export type GetGroupsForUserApiArg = {
  userId: string;
};
export type UpdateGroupApiResponse = /** status 200 Ok */ GroupDto;
export type UpdateGroupApiArg = {
  id: string;
  groupUpsertParams: GroupUpsertParams;
};
export type DeleteGroupApiResponse = /** status 200 Ok */ {
  success: boolean;
};
export type DeleteGroupApiArg = {
  id: string;
};
export type GetLevelOfSkillsApiResponse =
  /** status 200 Ok */ LevelOfSkillDto[];
export type GetLevelOfSkillsApiArg = void;
export type CreateLevelOfSkillApiResponse =
  /** status 200 Ok */ LevelOfSkillDto;
export type CreateLevelOfSkillApiArg = {
  levelOfSkillUpsertParams: LevelOfSkillUpsertParams;
};
export type UpdateLevelOfSkillApiResponse =
  /** status 200 Ok */ LevelOfSkillDto;
export type UpdateLevelOfSkillApiArg = {
  id: string;
  levelOfSkillUpsertParams: LevelOfSkillUpsertParams;
};
export type DeleteLevelOfSkillApiResponse = /** status 200 Ok */ boolean;
export type DeleteLevelOfSkillApiArg = {
  id: string;
};
export type GetMatterCommentsForUserApiResponse =
  /** status 200 Ok */ AugmentedMatter[];
export type GetMatterCommentsForUserApiArg = {
  userId: string;
};
export type GetMatterCommentsForMeApiResponse =
  /** status 200 Ok */ AugmentedMatter[];
export type GetMatterCommentsForMeApiArg = void;
export type UpdateMatterCommentApiResponse =
  /** status 200 Ok */ AugmentedMatter;
export type UpdateMatterCommentApiArg = {
  body: {
    comment: string;
    matterNumber: string;
    userId: string;
  };
};
export type UpdateMatterManagerApiResponse = /** status 200 Ok */ "true";
export type UpdateMatterManagerApiArg = {
  body: {
    value: boolean;
    userId: string;
    matterNumber: string;
  };
};
export type GetNotificationTokensApiResponse = /** status 200 Ok */ UserDto;
export type GetNotificationTokensApiArg = void;
export type CreateNotificationTokenApiResponse = /** status 200 Ok */ UserDto;
export type CreateNotificationTokenApiArg = {
  tokenType: string;
  notificationTokenCreationParams: NotificationTokenCreationParams;
};
export type DeleteNotificationTokenApiResponse = /** status 200 Ok */ boolean;
export type DeleteNotificationTokenApiArg = {
  id: string;
};
export type GetOfficesApiResponse = /** status 200 Ok */ OfficeDto[];
export type GetOfficesApiArg = void;
export type CreateOfficeApiResponse = /** status 200 Ok */ OfficeDto;
export type CreateOfficeApiArg = {
  officeCreationParams: OfficeCreationParams;
};
export type GetOfficesForUserApiResponse = /** status 200 Ok */ OfficeDto[];
export type GetOfficesForUserApiArg = {
  userId: string;
};
export type UpdateOfficeApiResponse = /** status 200 Ok */ OfficeDto;
export type UpdateOfficeApiArg = {
  id: string;
  officeUpdateParams: OfficeUpdateParams;
};
export type DeleteOfficeApiResponse = /** status 200 Ok */ {
  success: boolean;
};
export type DeleteOfficeApiArg = {
  id: string;
};
export type GetProjectCategoriesApiResponse =
  /** status 200 Ok */ ProjectCategoryDto[];
export type GetProjectCategoriesApiArg = void;
export type CreateProjectCategoryApiResponse =
  /** status 200 Ok */ ProjectCategoryDto;
export type CreateProjectCategoryApiArg = {
  projectCategoryUpsertParams: ProjectCategoryUpsertParams;
};
export type UpdateProjectCategoryApiResponse =
  /** status 200 Ok */ ProjectCategoryDto;
export type UpdateProjectCategoryApiArg = {
  id: string;
  projectCategoryUpsertParams: ProjectCategoryUpsertParams;
};
export type DeleteProjectCategoryApiResponse = /** status 200 Ok */ boolean;
export type DeleteProjectCategoryApiArg = {
  id: string;
};
export type GetProjectInvoicesApiResponse =
  /** status 200 Ok */ ProjectInvoiceDto[];
export type GetProjectInvoicesApiArg = {
  projectId: string;
};
export type CreateProjectInvoiceApiResponse =
  /** status 200 Ok */ ProjectInvoiceDto;
export type CreateProjectInvoiceApiArg = {
  projectId: string;
  projectInvoiceInsertParams: ProjectInvoiceInsertParams;
};
export type UpdateProjectInvoiceApiResponse =
  /** status 200 Ok */ ProjectInvoiceDto;
export type UpdateProjectInvoiceApiArg = {
  id: string;
  projectId: string;
  projectInvoiceUpdateParams: ProjectInvoiceUpdateParams;
};
export type DeleteProjectInvoiceApiResponse = /** status 200 Ok */ boolean;
export type DeleteProjectInvoiceApiArg = {
  id: string;
  projectId: string;
};
export type GetProjectStagesApiResponse =
  /** status 200 Ok */ ProjectStageDto[];
export type GetProjectStagesApiArg = void;
export type CreateProjectStageApiResponse =
  /** status 200 Ok */ ProjectStageDto;
export type CreateProjectStageApiArg = {
  projectStageUpsertParams: ProjectStageUpsertParams;
};
export type UpdateProjectStageApiResponse =
  /** status 200 Ok */ ProjectStageDto;
export type UpdateProjectStageApiArg = {
  id: string;
  projectStageUpsertParams: ProjectStageUpsertParams;
};
export type DeleteProjectStageApiResponse = /** status 200 Ok */ boolean;
export type DeleteProjectStageApiArg = {
  id: string;
};
export type GetProjectTimeReportsApiResponse =
  /** status 200 Ok */ ProjectTimeReportDto[];
export type GetProjectTimeReportsApiArg = {
  projectId: string;
};
export type CreateProjectTimeReportApiResponse =
  /** status 200 Ok */ ProjectTimeReportDto;
export type CreateProjectTimeReportApiArg = {
  projectId: string;
  projectTimeReportInsertParams: ProjectTimeReportInsertParams;
};
export type UpdateProjectTimeReportApiResponse =
  /** status 200 Ok */ ProjectTimeReportDto;
export type UpdateProjectTimeReportApiArg = {
  id: string;
  projectId: string;
  projectTimeReportUpdateParams: ProjectTimeReportUpdateParams;
};
export type DeleteProjectTimeReportApiResponse = /** status 200 Ok */ boolean;
export type DeleteProjectTimeReportApiArg = {
  id: string;
  projectId: string;
};
export type GetProjectsApiResponse = /** status 200 Ok */ ProjectWithUsersDto[];
export type GetProjectsApiArg = {
  all?: string;
  office?: string;
};
export type CreateProjectApiResponse = /** status 200 Ok */ ProjectDto;
export type CreateProjectApiArg = {
  projectCreationParams: ProjectCreationParams;
};
export type GetProjectApiResponse = /** status 200 Ok */ ProjectWithUsersDto;
export type GetProjectApiArg = {
  id: string;
};
export type UpdateProjectApiResponse = /** status 200 Ok */ ProjectDto;
export type UpdateProjectApiArg = {
  id: string;
  projectUpdateParams: ProjectUpdateParams;
};
export type DeleteProjectApiResponse = /** status 200 Ok */ {
  success: boolean;
};
export type DeleteProjectApiArg = {
  id: string;
};
export type GetFilteredProjectsApiResponse =
  /** status 200 Ok */ ProjectWithUsersDto[];
export type GetFilteredProjectsApiArg = {
  body: {
    filters: ProjectFilterParams;
  };
};
export type ArchiveProjectApiResponse =
  /** status 200 Ok */ ProjectWithUsersDto;
export type ArchiveProjectApiArg = {
  id: string;
};
export type UnarchiveProjectApiResponse =
  /** status 200 Ok */ ProjectWithUsersDto;
export type UnarchiveProjectApiArg = {
  id: string;
};
export type AddProjectToUserApiResponse = /** status 200 Ok */ UserDto;
export type AddProjectToUserApiArg = {
  id: string;
  userId: string;
};
export type DeleteProjectFromUserApiResponse = /** status 200 Ok */ UserDto;
export type DeleteProjectFromUserApiArg = {
  id: string;
  userId: string;
};
export type AddProjectToUsersApiResponse = /** status 200 Ok */ {
  status: "ok";
};
export type AddProjectToUsersApiArg = {
  id: string;
  body: {
    ids: string[];
  };
};
export type CreateSkillApiResponse = /** status 200 Ok */ SkillDto;
export type CreateSkillApiArg = {
  skillCreationParams: SkillCreationParams;
};
export type UpdateSkillApiResponse = /** status 200 Ok */ SkillDto;
export type UpdateSkillApiArg = {
  id: string;
  skillUpdateParams: SkillUpdateParams;
};
export type DeleteSkillApiResponse = /** status 200 Ok */ SkillDto[] | null;
export type DeleteSkillApiArg = {
  id: string;
};
export type GetStatisticsApiResponse = /** status 200 Ok */ IUsersStatistics;
export type GetStatisticsApiArg = {
  startDate: string;
  endDate: string;
  body: {
    filters: UserFilterParams;
  };
};
export type GetCurrentUserApiResponse = /** status 200 Ok */ UserDto | null;
export type GetCurrentUserApiArg = void;
export type GetUsersApiResponse = /** status 200 Ok */ UserDto[] | null;
export type GetUsersApiArg = void;
export type CreateUserApiResponse = /** status 200 Ok */ UserDto | null;
export type CreateUserApiArg = {
  userCreationParams: UserCreationParams;
};
export type GetFilteredUsersApiResponse = /** status 200 Ok */ UserDto[];
export type GetFilteredUsersApiArg = {
  body: {
    filters: UserFilterParams;
  };
};
export type GetFilteredUserIdsApiResponse = /** status 200 Ok */ string[];
export type GetFilteredUserIdsApiArg = {
  body: {
    filters: UserFilterParams;
  };
};
export type GetUserWorkloadHistoryApiResponse =
  /** status 200 Ok */ WorkloadHistoryDto[];
export type GetUserWorkloadHistoryApiArg = {
  id: string;
};
export type GetUserByIdApiResponse = /** status 200 Ok */ UserDto | null;
export type GetUserByIdApiArg = {
  id: string;
};
export type UpdateUserApiResponse = /** status 200 Ok */ UserDto | null;
export type UpdateUserApiArg = {
  id: string;
  userUpdateParams: UserUpdateParams;
};
export type DeleteUserApiResponse = /** status 200 Ok */ {
  success: boolean;
};
export type DeleteUserApiArg = {
  id: string;
};
export type GetUncompletedUserByIdApiResponse =
  /** status 200 Ok */ UserDto | null;
export type GetUncompletedUserByIdApiArg = {
  id: string;
};
export type CreateMultipleUsersApiResponse = /** status 200 Ok */ "true" | null;
export type CreateMultipleUsersApiArg = {
  multipleUserCreationParams: MultipleUserCreationParams;
};
export type UpdateUserProfilePictureApiResponse =
  /** status 200 Ok */ UserDto | null;
export type UpdateUserProfilePictureApiArg = {
  id: string;
  body: {
    image: Blob;
  };
};
export type ChangeUserTeamApiResponse = /** status 200 Ok */ UserDto | null;
export type ChangeUserTeamApiArg = {
  id: string;
  changeUserTeamParams: ChangeUserTeamParams;
};
export type UpdateUserAdminStatusApiResponse =
  /** status 200 Ok */ UserDto | null;
export type UpdateUserAdminStatusApiArg = {
  id: string;
  updateUserAdminStatusParams: UpdateUserAdminStatusParams;
};
export type CreateVacationApiResponse = /** status 200 Ok */ UserDto;
export type CreateVacationApiArg = {
  userId: string;
  vacationUpsertParams: VacationUpsertParams;
};
export type UpdateVacationApiResponse = /** status 200 Ok */ UserDto;
export type UpdateVacationApiArg = {
  userId: string;
  vacationId: string;
  vacationUpsertParams: VacationUpsertParams;
};
export type DeleteVacationApiResponse = /** status 200 Ok */ UserDto | null;
export type DeleteVacationApiArg = {
  userId: string;
  vacationId: string;
};
export type GetFilteredWorkloadHistoryApiResponse =
  /** status 200 Ok */ AverageUserWorkloadHistoryDto;
export type GetFilteredWorkloadHistoryApiArg = {
  scope: string;
  id: string;
  days: string;
};
export type UpdateWorkloadMessageApiResponse = /** status 200 Ok */ UserDto;
export type UpdateWorkloadMessageApiArg = {
  workloadMessageCreationParams: WorkloadMessageCreationParams;
};
export type RefreshUserApiResponse = /** status 200 Ok */ UserDto;
export type RefreshUserApiArg = void;
export type CreateWorkloadApiResponse = /** status 200 Ok */ UserDto;
export type CreateWorkloadApiArg = {
  type: string;
  workloadCreationParams: WorkloadCreationParams;
};
export type CreateWorkplaceForecastApiResponse = /** status 200 Ok */ UserDto;
export type CreateWorkplaceForecastApiArg = {
  index: string;
  workplaceForecastCreationParams: WorkplaceForecastCreationParams;
};
export type GetWorkplaceHistoryApiResponse =
  /** status 200 Ok */ WorkplaceHistoryDto;
export type GetWorkplaceHistoryApiArg = {
  scope: string;
  id: string;
  days: string;
};
export type ApiError = {
  message: string;
  code?: string;
};
export type LoginParams = {
  email: string;
  password: string;
};
export type UserSignUpInputErrors = {
  entityType: "UserSignUpInputErrors";
  name?: string;
  email?: string;
  phone?: string;
  password?: string;
  passwordConfirmation?: string;
};
export type RegisterParams = {
  name: string;
  title?: string;
  email: string;
  phone: string;
  password?: string;
  passwordConfirmation?: string;
  sso?: boolean;
  group?: string;
  office?: string;
};
export type ChangePasswordInputErrors = {
  entityType: "ChangePasswordInputErrors";
  oldPassword?: string;
  newPassword?: string;
  newPasswordConfirmation?: string;
};
export type ChangePasswordParams = {
  newPassword: string;
  newPasswordConfirmation: string;
  oldPassword: string;
};
export type SetPasswordInputErrors = {
  entityType: "SetPasswordInputErrors";
  newPassword?: string;
  newPasswordConfirmation?: string;
};
export type SetPasswordParams = {
  newPassword: string;
  newPasswordConfirmation: string;
};
export type ResetPasswordParams = {
  resetEmail: string;
};
export type GroupDto = {
  _id?: string | null;
  _v?: number | null;
  company?: string;
  name?: string;
  ssoIdentifiers: string[];
  created?: string;
};
export type CompanyDto = {
  _id?: string | null;
  _v?: number | null;
  useCompetences?: boolean;
  name?: string;
  created?: string;
  azureADTenantId?: string;
  allowFederation?: boolean;
  allowGroupSelection?: boolean;
  smtpSecure?: boolean;
  smtpHost?: string;
  smtpPort?: string;
  smtpSender?: string;
  smtpUser?: string;
  smtpPassword?: string;
  ssoLoginUrl?: string;
  ssoCertificate?: string;
  customHtml?: string;
};
export type OfficeDto = {
  _id?: string | null;
  _v?: number | null;
  name?: string;
  created?: string;
  group?: GroupDto | string;
  company?: CompanyDto | string;
};
export type ProjectStageDto = {
  _id?: string | null;
  _v?: number | null;
  company?: string;
  name?: string;
  color?: string;
};
export type ProjectCategoryDto = {
  _id?: string | null;
  _v?: number | null;
  company?: string;
  name?: string;
};
export type ProjectDto = {
  _id?: string | null;
  _v?: number | null;
  archived?: boolean | null;
  created?: string | null;
  name: string;
  company?: string;
  group?: GroupDto | null;
  office?: OfficeDto | null;
  message?: {
    updated?: string | null;
    message?: string | null;
  } | null;
  startDate?: string | null;
  endDate?: string | null;
  manager?: UserDto;
  description?: string | null;
  projectStage?: ProjectStageDto | null;
  projectCategories?: ProjectCategoryDto[];
};
export type LevelOfSkillDto = {
  _id?: string | null;
  _v?: number | null;
  company?: string;
  name?: string;
  level?: number;
};
export type BaseDto = {
  _id?: string | null;
  _v?: number | null;
};
export type CompetenceUserDto = {
  _id?: string | null;
  _v?: number | null;
  currentWorkload?: number | null;
  email?: string | null;
  name?: string | null;
  profilePicture?: string | null;
  title?: string | null;
  skills: ({
    level: LevelOfSkillDto;
    competence?: string | null;
  } & BaseDto)[];
};
export type CompetenceDto = {
  _id?: string | null;
  _v?: number | null;
  users?: CompetenceUserDto[] | null;
  name?: string | null;
  company?: string | null;
  root?: boolean | null;
  parent?:
    | ({
        subCategory?: boolean | null;
        parent?: string | null;
        root?: boolean;
        company?: string | null;
        name?: string | null;
        users?: any[];
      } & BaseDto)
    | null;
  subCategory?: boolean | null;
  averageSkillLevel?: number | null;
  children?:
    | ({
        subCategory?: boolean | null;
        parent?: string | null;
        root?: boolean;
        company?: string | null;
        name?: string | null;
        users?: any[];
      } & BaseDto)[]
    | null;
};
export type SkillDto = {
  _id?: string | null;
  _v?: number | null;
  competence?: string | CompetenceDto;
  level?: string | LevelOfSkillDto;
};
export type VacationDto = {
  _id?: string | null;
  _v?: number | null;
  created?: string;
  title?: string | null;
  startDate?: string;
  endDate?: string | null;
};
export type UserDto = {
  _id?: string | null;
  _v?: number | null;
  admin?: boolean | null;
  companyAdmin?: boolean | null;
  completed?: boolean | null;
  currentWorkload?: number | null;
  futureWorkload?: number | null;
  workloadForecast?: number[] | null;
  workloadForecastDate?: string | null;
  workplaceForecast?: number[] | null;
  workplaceDefault?: number[] | null;
  tags?: string[] | null;
  cvpartnerData?: string[] | null;
  projects?: (ProjectDto | string)[];
  onLeave?: boolean | null;
  atHome?: boolean | null;
  returnDate?: string | null;
  startYear?: number | null;
  resetHash?: string | null;
  loginCode?: string | null;
  updateReminders?: number | null;
  administratorPriviliges?: boolean | null;
  competences?: (CompetenceDto | string)[];
  skills?: (SkillDto | string)[];
  vacations?: (VacationDto | string)[];
  name?: string | null;
  email: string;
  company?: CompanyDto | string;
  group?: GroupDto | string;
  secondaryGroup?: GroupDto | string;
  office?: OfficeDto | string;
  secondaryOffice?: OfficeDto | string;
  created?: string | null;
  notificationTokens?:
    | {
        token: string;
        created?: string | null;
        tokenType: string;
      }[]
    | null;
  lastUpdated?: string | null;
  phone?: string | null;
  profilePicture?: string | null;
  message?: {
    updated?: string | null;
    message?: string | null;
  } | null;
  title?: string | null;
};
export type AzureUser = {
  userPrincipalName?: string | null;
  surname?: string | null;
  givenName?: string | null;
  displayName?: string | null;
  mail?: string | null;
  id?: string | null;
};
export type CompanyUpdateParams = {
  name: string;
  customHtml?: string;
};
export type CompanySmtpConfigParams = {
  smtpHost?: string;
  smtpPort?: string;
  smtpSender?: string;
  smtpSecure?: boolean;
  smtpUser?: string | null;
  smtpPassword?: string | null;
};
export type CompanySsoConfigParams = {
  ssoLoginUrl?: string;
  ssoCertificate?: string;
  allowFederation?: boolean;
  allowGroupSelection?: boolean;
};
export type CompetenceCreationParams = {
  name: string;
  root: boolean | null;
  subCategory: boolean | null;
  parentId: string | null;
  users: string[] | null;
};
export type CompetenceUpdateParams = {
  name: string;
  root: boolean | null;
  subCategory: boolean | null;
  parentId: string | null;
};
export type MatterDto = {
  displayName?: string;
  clientDisplayName?: string;
  number?: string;
  nonBillable?: boolean;
  totalHours?: number;
  resposibleTimekeeperName?: string;
  resposibleTimekeeperNumber?: string;
};
export type WeeklyOverviewDto = {
  weekNumber?: string;
  totalHours?: number;
  billableHours?: number;
};
export type TimekeeperDto = {
  _id?: string | null;
  _v?: number | null;
  created?: string;
  company?: string;
  displayName?: string;
  email?: string;
  title?: string;
  number?: string;
  hireDate?: string;
  office?: string;
  department?: string;
  rateClass?: string;
  sectionValue?: string;
  matters?: MatterDto[];
  weeklyOverview?: WeeklyOverviewDto[];
  weeklyAverage?: number;
  utilizationAverage?: number;
  utilizationAverageLastMonth?: number;
};
export type UserUtilization = {
  displayName?: string;
  profilePicture?: string;
  title?: string;
  userId?: string;
  avgWorkload?: number | null;
  utilizationAverage?: number;
  utilizationAverageLastMonth?: number;
  weeklyAverage?: number;
  weeklyOverview?: {
    billableHours?: number;
    totalHours?: number;
    weekNumber?: string;
  }[];
};
export type UtilizationReport = UserUtilization[];
export type UserFilterParams = {
  users?: string[];
  competences?: string[];
  projects?: string[];
  groups?: string[];
  offices?: string[];
  term?: string;
  cvpartnerData?: string[];
  completed?: boolean;
};
export type AugmentedMatter = {
  _id?: string;
  displayName?: string;
  clientDisplayName?: string;
  number?: string;
  nonBillable?: boolean;
  totalHours?: number;
  resposibleTimekeeperName?: string;
  resposibleTimekeeperNumber?: string;
  comment?: string;
  manager?: string;
};
export type UserDetailed = {
  displayName?: string;
  profilePicture?: string;
  title?: string;
  userId?: string;
  currentWorkload?: number | null;
  matters?: AugmentedMatter[];
};
export type DetailedReport = UserDetailed[];
export type ForecastDto = {
  _id?: string | null;
  _v?: number | null;
  created?: string;
  status?: "0" | "1" | "2" | "3" | "away";
  startDate?: string;
  endDate?: string;
  comment?: string;
  externalSource?: string;
  user?: string;
  company?: string;
};
export type ForecastUpsertParams = {
  status: "0" | "1" | "2" | "3" | "away";
  startDate: string;
  endDate: string;
  comment?: string | null;
};
export type GroupUpsertParams = {
  name: string;
  ssoIdentifiers: string[];
};
export type LevelOfSkillUpsertParams = {
  name?: string;
  level?: number;
};
export type NotificationTokenCreationParams = {
  token: string;
};
export type OfficeCreationParams = {
  name?: string;
  group?: string;
};
export type OfficeUpdateParams = {
  name?: string;
};
export type ProjectCategoryUpsertParams = {
  name?: string;
};
export type ProjectInvoiceDto = {
  _id?: string | null;
  _v?: number | null;
  company?: string;
  project?: string;
  date?: string;
  amount?: number;
  vat?: number;
  identification?: string;
  currency?: string;
};
export type ProjectInvoiceInsertParams = {
  date?: string | null;
  amount: number | null;
  vat: number | null;
  identification: string | null;
  currency: string | null;
};
export type ProjectInvoiceUpdateParams = {
  amount: number | null;
  vat: number | null;
  identification: string | null;
  currency: string | null;
};
export type ProjectStageUpsertParams = {
  name?: string;
  color?: string;
};
export type ProjectTimeReportDto = {
  _id?: string | null;
  _v?: number | null;
  company?: string;
  user?: string;
  project?: string;
  date?: string;
  hours?: number;
};
export type ProjectTimeReportInsertParams = {
  user?: string;
  date?: string;
  hours: number;
};
export type ProjectTimeReportUpdateParams = {
  hours: number;
};
export type UserWithProjectTimeReportsDto = {
  _id?: string | null;
  _v?: number | null;
  admin?: boolean | null;
  companyAdmin?: boolean | null;
  completed?: boolean | null;
  currentWorkload?: number | null;
  futureWorkload?: number | null;
  workloadForecast?: number[] | null;
  workloadForecastDate?: string | null;
  workplaceForecast?: number[] | null;
  workplaceDefault?: number[] | null;
  tags?: string[] | null;
  cvpartnerData?: string[] | null;
  projects?: (ProjectDto | string)[];
  onLeave?: boolean | null;
  atHome?: boolean | null;
  returnDate?: string | null;
  startYear?: number | null;
  resetHash?: string | null;
  loginCode?: string | null;
  updateReminders?: number | null;
  administratorPriviliges?: boolean | null;
  competences?: (CompetenceDto | string)[];
  skills?: (SkillDto | string)[];
  vacations?: (VacationDto | string)[];
  name?: string | null;
  email: string;
  company?: CompanyDto | string;
  group?: GroupDto | string;
  secondaryGroup?: GroupDto | string;
  office?: OfficeDto | string;
  secondaryOffice?: OfficeDto | string;
  created?: string | null;
  notificationTokens?:
    | {
        token: string;
        created?: string | null;
        tokenType: string;
      }[]
    | null;
  lastUpdated?: string | null;
  phone?: string | null;
  profilePicture?: string | null;
  message?: {
    updated?: string | null;
    message?: string | null;
  } | null;
  title?: string | null;
  projectTimeReports?: ProjectTimeReportDto[];
  projectHours?: number;
};
export type ProjectWithUsersDto = {
  _id?: string | null;
  _v?: number | null;
  archived?: boolean | null;
  created?: string | null;
  name: string;
  company?: string;
  group?: GroupDto | null;
  office?: OfficeDto | null;
  message?: {
    updated?: string | null;
    message?: string | null;
  } | null;
  startDate?: string | null;
  endDate?: string | null;
  manager?: UserDto;
  description?: string | null;
  projectStage?: ProjectStageDto | null;
  projectCategories?: ProjectCategoryDto[];
  users?: UserWithProjectTimeReportsDto[];
  averageWorkload?: number;
};
export type ProjectCreationParams = {
  name: string;
  group?: string;
  office?: string;
  manager?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  addUser?: boolean;
};
export type ProjectUpdateParams = {
  name: string;
  archived?: boolean;
  message?: string;
  manager?: string;
  projectStage?: string;
  projectCategories?: string[];
  description?: string;
  startDate?: string;
  endDate?: string;
};
export type ProjectFilterParams = {
  groups?: string[];
  offices?: string[];
  term?: string;
  stages?: string[];
  categories?: string[];
};
export type SkillCreationParams = {
  levelOfSkillId?: string;
  competenceId?: string;
  userId?: string;
};
export type SkillUpdateParams = {
  levelOfSkillId?: string;
};
export type IUsersStatistics = {
  workloadDistributions: {
    [key: string]: number;
  };
  averageWorkload: number;
  userWorkloadData: {
    [key: string]: {
      portionOfAvailable: number | null;
      portionOfSeriouslyBusy: number | null;
      avgWorkload: number | null;
    };
  };
  averageWorkloadPerUser: {
    [key: string]: (number | null)[];
  };
  averageWorkloadOverTime: number[][] | null;
  competenceWorkload: {
    [key: string]: {
      avgWorkload: number | null;
      totalAvgWorkload: number | null;
      nbrOfUsers: number | null;
    };
  };
  teamWorkload: {
    [key: string]: {
      avgWorkload: number | null;
      totalAvgWorkload: number | null;
      nbrOfUsers: number | null;
    };
  };
  currentWorkloadDistribution: {
    [key: string]: number;
  };
  workloadForecast: {
    same: number;
    lower: number;
    higher: number;
  };
  nbrOfUsers: number;
  peakOfficeOccupancy: number;
  percentOnLeave: number;
  percentOfMoreThan4daysHome: number;
  percentOfMoreThan4daysAtOffice: number;
  workFromHomePerWeekday: {
    [key: string]: number;
  };
  totalNbrOfWeekdaysFromHome: {
    [key: string]: number;
  };
  WFHToWorkloadCorrelation: ({
    r: number;
    y: number;
    x: number;
  } | null)[];
  portionOfUsersWorkingFromHomeOverTime: {
    portionAtHome: number;
    date: string;
  }[];
  nbrOfHomeDaysPerWeek: {
    [key: string]: number;
  };
  data: {
    offices: OfficeDto[];
    groups: GroupDto[];
    competences: CompetenceDto[];
    users: UserDto[];
  };
};
export type UserInputErrors = {
  entityType: "UserInputErrors";
  name?: string;
  email?: string;
  admin?: string;
};
export type UserCreationParams = {
  name: string;
  email: string;
  admin: boolean;
  tags?: string;
  title?: string;
  companyAdmin?: boolean;
  group?: string;
  office?: string;
};
export type WorkloadHistoryDto = {
  workload: number;
  created?: string | null;
};
export type UserUpdateParams = {
  name: string;
  email: string;
  admin: boolean;
  phone?: string;
  tags?: string[];
  onLeave?: boolean;
  atHome?: boolean;
  leaveReason?: string;
  returnDate?: string;
  workloadForecastDate?: string | null;
  startYear?: number;
  title?: string;
  updateReminders?: number;
  observer?: boolean;
  competences?: string[];
};
export type MultipleUserCreationParams = {
  users: {
    azureADId?: string;
    name?: string;
    email: string;
  }[];
  group?: string;
  office?: string;
  admin: boolean;
  secondaryEnabled?: boolean;
  secondaryOffice?: string;
  secondaryGroup?: string;
};
export type ChangeUserTeamParams = {
  office: string | null;
  secondaryOffice?: string;
  group: string | null;
  secondaryGroup?: string;
  secondaryEnabled?: boolean;
};
export type UpdateUserAdminStatusParams = {
  admin: boolean;
  administratorPriviliges: boolean;
};
export type VacationUpsertParams = {
  title: string;
  startDate: string;
  endDate: string | null;
};
export type AverageUserWorkloadHistoryDto = {
  [key: string]: (number | null)[];
};
export type WorkloadMessageCreationParams = {
  message: string;
};
export type WorkloadCreationParams = {
  workscale: number;
  id?: string;
  source?: string;
};
export type WorkplaceForecastCreationParams = {
  option: number | null;
  future: boolean;
};
export type WorkplaceHistoryDto = {
  [key: string]: ({
    onLeave: boolean;
    atHome: boolean;
  } | null)[];
};
export const {
  useRefreshTokenQuery,
  useLoginMutation,
  useRegisterUserMutation,
  useChangePasswordMutation,
  useSetPasswordMutation,
  useResetPasswordMutation,
  useResendUserInviteMutation,
  useUpdateAzureTenantMutation,
  useRemoveAzureTenantMutation,
  useSignInAzureUserWithCodeMutation,
  useSignInAzureUserWithTokenMutation,
  useGetAzureUsersQuery,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  useGetSsoAssertionMutation,
  useGetSsoLoginQuery,
  useTestCompanySmtpConfigMutation,
  useUpdateCompanySmtpConfigMutation,
  useUpdateCompanySsoConfigMutation,
  useGetCompetencesQuery,
  useCreateCompetenceMutation,
  useGetCompetenceQuery,
  useUpdateCompetenceMutation,
  useDeleteCompetenceMutation,
  useAddCompetenceToUsersMutation,
  useAddCompetenceToUserMutation,
  useUpdateTimekeeperMutation,
  useGetUtilizationReportMutation,
  useGetDetailedReportMutation,
  useGetForecastsQuery,
  useCreateForecastMutation,
  useUpdateForecastMutation,
  useDeleteForecastMutation,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useGetGroupsForUserQuery,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useGetLevelOfSkillsQuery,
  useCreateLevelOfSkillMutation,
  useUpdateLevelOfSkillMutation,
  useDeleteLevelOfSkillMutation,
  useGetMatterCommentsForUserQuery,
  useGetMatterCommentsForMeQuery,
  useUpdateMatterCommentMutation,
  useUpdateMatterManagerMutation,
  useGetNotificationTokensQuery,
  useCreateNotificationTokenMutation,
  useDeleteNotificationTokenMutation,
  useGetOfficesQuery,
  useCreateOfficeMutation,
  useGetOfficesForUserQuery,
  useUpdateOfficeMutation,
  useDeleteOfficeMutation,
  useGetProjectCategoriesQuery,
  useCreateProjectCategoryMutation,
  useUpdateProjectCategoryMutation,
  useDeleteProjectCategoryMutation,
  useGetProjectInvoicesQuery,
  useCreateProjectInvoiceMutation,
  useUpdateProjectInvoiceMutation,
  useDeleteProjectInvoiceMutation,
  useGetProjectStagesQuery,
  useCreateProjectStageMutation,
  useUpdateProjectStageMutation,
  useDeleteProjectStageMutation,
  useGetProjectTimeReportsQuery,
  useCreateProjectTimeReportMutation,
  useUpdateProjectTimeReportMutation,
  useDeleteProjectTimeReportMutation,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetFilteredProjectsMutation,
  useArchiveProjectMutation,
  useUnarchiveProjectMutation,
  useAddProjectToUserMutation,
  useDeleteProjectFromUserMutation,
  useAddProjectToUsersMutation,
  useCreateSkillMutation,
  useUpdateSkillMutation,
  useDeleteSkillMutation,
  useGetStatisticsMutation,
  useGetCurrentUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useGetFilteredUsersMutation,
  useGetFilteredUserIdsMutation,
  useGetUserWorkloadHistoryQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUncompletedUserByIdQuery,
  useCreateMultipleUsersMutation,
  useUpdateUserProfilePictureMutation,
  useChangeUserTeamMutation,
  useUpdateUserAdminStatusMutation,
  useCreateVacationMutation,
  useUpdateVacationMutation,
  useDeleteVacationMutation,
  useGetFilteredWorkloadHistoryQuery,
  useUpdateWorkloadMessageMutation,
  useRefreshUserMutation,
  useCreateWorkloadMutation,
  useCreateWorkplaceForecastMutation,
  useGetWorkplaceHistoryQuery,
} = api;

