import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme, useTheme } from '@material-ui/core';
import CompetenceAverageSkillLevel from '../competences/CompetenceAverageSkillLevel';
import CompetenceCategory from '../competences/CompetenceCategory';
import { useHistory } from 'react-router-dom';
import { useAddCompetenceToUserMutation, useGetCompetencesQuery, useGetCurrentUserQuery, useGetDetailedReportMutation, useGetUserByIdQuery, usePrefetch } from 'utils/wemble-api';
import organization from 'utils/organization';
import FilterSelect from './FilterSelect';
import { DetailedReport } from 'utils/wemble-api.generated';
import { MatterPM } from 'components/Home/DetailedTable';
import Avatar from './members/Avatar';

const useStyles = makeStyles((theme) => ({
  userCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  userCard__info: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > div': {
      marginRight: 20,
      '&:nth-last-child(1)': {
        marginRight: 0,
      },
    },
  },
  info__icon: {
    display: 'inline-block',
    width: 16,
    height: 16,
    backgroundColor: 'red',
    borderRadius: '50%',
  },
  info__name: {
    fontWeight: 500,
    fontSize: 18,
    margin: 0,
  },
  info__link: {
    fontSize: 14,
    color: theme.palette.gray.g2,
    margin: 0,
  },
  userCard__stats: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  stats__card: {
    width: 'calc(50% - 8px)',
    backgroundColor: theme.palette.blue.opacity.b16,
    borderRadius: 16,
    padding: 20,
  },
  stats__title: {
    fontWeight: 600,
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.palette.gray.g2,
  },
  stats__stat: {
    fontSize: 44,
    fontWeight: 600,
  },
  userCard__list: {
    marginTop: 20,
    '& > h2': {
      fontSize: 24,
      fontWeight: 500,
      margin: 0,
    },
  },
  table: {
    '& .MuiTableCell-root': {
      border: 'none',
      padding: '12px 0 12px 12px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }

    },
    '& .MuiTableCell-head': {
      fontSize: 12,
      textTransform: 'uppercase',
      color: theme.palette.gray.g10,
      padding: '12px 6px 0 12px',
    },
    '& .MuiTableBody-root .MuiTableRow-root': {
      backgroundColor: theme.palette.gray.g7,
      borderCollapse: 'separate',
      borderSpacing: '0 1em',
    },
    '& .MuiTableBody-root .MuiTableRow-root > td': {
      color: 'black',
      paddingRight: 6,
    },
    '& .MuiTableBody-root .MuiTableRow-root > td:nth-child(1)': {
      borderRadius: '6px 0 0 6px',
    },
    '& .MuiTableBody-root .MuiTableRow-root > td:nth-last-child(1)': {
      borderRadius: '0 6px 6px 0',
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
      border: 'none',
    },
  },
  table_subtitle: {
    fontSize: '12 !important',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px !important'
    }

  },
  table__members: {
    backgroundColor: theme.palette.gray.g9,
    display: 'inline-block',
    width: 30,
    height: 30,
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '30px',
  },
  table__date: {
    backgroundColor: theme.palette.gray.g9,
    display: 'inline-block',
    height: 30,
    borderRadius: 15,
    textAlign: 'center',
    lineHeight: '30px',
    paddingInline: 12,
  },
  table__round: {
    backgroundColor: theme.palette.red.opacity.r16,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    fontSize: 12,
    padding: '6px 10px',
    borderRadius: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      padding: '4px 8px',
      borderRadius: 20,
    },

  },
  table__spacer: {
    height: 6,
  },
  select__add: {
    backgroundColor: theme.palette.gray.g7,
    borderRadius: 8,
    marginTop: -2,
    minWidth: 32,
    padding: 9,
    float: 'right',
    cursor: 'pointer',
    fontSize: 12


  },
  goldStar: {
    display: 'inline-block',
    width: 16,
    height: 16,
    background: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'%23FFD700\'%3E%3Cpath d=\'M12 .587l3.668 7.431 8.21 1.193-5.938 5.788 1.403 8.18L12 18.896l-7.343 3.863 1.403-8.18L.121 9.211l8.21-1.193L12 .587z\'/%3E%3C/svg%3E") no-repeat center center',
    backgroundSize: 'contain',
  },
  greyStar: {
    display: 'inline-block',
    width: 16,
    height: 16,
    background: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'%23D3D3D3\'%3E%3Cpath d=\'M12 .587l3.668 7.431 8.21 1.193-5.938 5.788 1.403 8.18L12 18.896l-7.343 3.863 1.403-8.18L.121 9.211l8.21-1.193L12 .587z\'/%3E%3C/svg%3E") no-repeat center center',
    backgroundSize: 'contain',
  },
  avatarCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 10,
  }

}));

function statusColor(status: number | null | undefined, theme: Theme) {
  switch (status) {
    case 0:
      return theme.palette.blue.b2;
    case 1:
      return theme.palette.green.g4;
    case 2:
      return theme.palette.yellow.y3;
    case 3:
      return theme.palette.red.r1;
    default:
      return '#828282';
  }
}

const formatDate = (date: string) => {
  return date.substr(0, 10).replaceAll('-', '.');
};

const UserCard = ({ userId }: { userId: string }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { data: user, isSuccess: userLoaded } = useGetUserByIdQuery({ id: userId }, { refetchOnMountOrArgChange: 5 });
  const { data: competences } = useGetCompetencesQuery(undefined, { skip: !userLoaded });
  const prefetchProject = usePrefetch('getProject');
  const prefetchCompetence = usePrefetch('getCompetence');
  const [addCompetence] = useAddCompetenceToUserMutation();

  const [getDetailedReport] = useGetDetailedReportMutation();
  const [detailedReport, setDetailedReport] = useState<DetailedReport | undefined>();
  const { data: currentUser } = useGetCurrentUserQuery();


  const showMatters = organization(currentUser?.company).enableUtilizationView && (organization(currentUser?.company).name != "Synch" || (currentUser?.admin || currentUser?.administratorPriviliges || currentUser?.companyAdmin))
  // const showMatters = ((organization(currentUser?.company).enableUtilizationView && ((organization(currentUser?.company).name == "Synch" && (currentUser?.admin || currentUser?.administratorPriviliges || currentUser?.companyAdmin)) || ["66309b91113dc300150542f6", "5e425ccb6d4ea9001678e002", "5daeb8637e1c4600165d587f", "5da2f6c75438640016f8da9f"].includes(typeof currentUser?.group == 'string' ? currentUser?.group : (currentUser?.group?._id || '')) || ["Maria Antrea", "Mike Specter"].includes(currentUser?.name || ''))) ? (currentUser?.admin || currentUser?.administratorPriviliges || currentUser?.companyAdmin) ? true : true : false);



  const fetchUtilizationReport = useCallback(
    async () => {

      if (!userId) return;

      const res = await getDetailedReport({
        body: {
          filters: { users: [userId], term: undefined }
        },
      });
      if ('data' in res) {
        showMatters && setDetailedReport(res.data)
      }

    },
    [userId],
  );

  useEffect(() => {
    fetchUtilizationReport();
  }, [fetchUtilizationReport]);




  const handleLink = (obj, type) => {
    if (type === 'project') {
      history.push({
        pathname: `/projects/${obj._id}`,
        state: obj,
      });
    } else if (type === 'competence') {
      history.push(`/competences/${obj._id}`);
    } else {
      console.log('type not found');
    }
  };

  if (!user) return null;

  const matterTypes = [
    { matterType: 'Private M&A', count: 12, interest: true },
    { matterType: 'Public M&A', count: 10, interest: true },
    { matterType: 'Technology IPO', count: 8, interest: true },
    { matterType: 'Litigation - Patent Dispute', count: 5, interest: false },
    { matterType: 'Litigation - Breach of Contract', count: 4, interest: false },
    { matterType: 'Real Estate - Commercial Leasing', count: 3, interest: false },
    { matterType: 'Real Estate - Residential Development', count: 2, interest: false },
  ];

  const clientIndustries = [
    { clientIndustry: 'Banking & Finance', count: 15, interest: true },
    { clientIndustry: 'Technology', count: 12, interest: true },
    { clientIndustry: 'Healthcare & Pharmaceuticals', count: 9, interest: true },
    { clientIndustry: 'Construction & Real Estate', count: 8, interest: false },
    { clientIndustry: 'Retail & Consumer Goods', count: 6, interest: false },
    { clientIndustry: 'Energy & Utilities', count: 5, interest: false },
    { clientIndustry: 'Automotive', count: 3, interest: false },
  ];

  const staffedWith = [
    { name: 'Victoria Reyes', profilePicture: 'demo/photos/women-hispanic-young/OIG.h3slZ_1TR8yuAXefDjrA.jpeg', count: 10, interest: true },
    { name: 'Diane Yuen', profilePicture: 'demo/photos/women-asian-old/OIG.SF1rWcnKP87CviUmTKHK.jpeg', count: 7, interest: true },
    { name: 'Violet Roberts', profilePicture: 'demo/photos/women-white-young/OIG.RFFgXCPbBfgXrptDkPas.jpeg', count: 5, interest: true },
    { name: 'Luke Roberts', profilePicture: 'demo/photos/men-white-young/OIG.2duRmh4cYnXTR5lmn4K8.jpeg', count: 2, interest: true },
    { name: 'Nathan Taylor', profilePicture: 'demo/photos/men-white-young/OIG.jdTXkKVVoKfosz8MfMza.jpeg', count: 12, interest: false },
    { name: 'Jerry Nelson', profilePicture: 'demo/photos/men-white-old/OIG.FSaeBuCa4p0Xlbesepn2.jpeg', count: 9, interest: false },
    { name: 'Zachary Leung', profilePicture: 'demo/photos/men-asian-young/OIG.mGgX9n61IQfUj8HlsMOp.jpeg', count: 8, interest: false },
    { name: 'Shaniqua Morris', profilePicture: 'demo/photos/women-black-young/OIG.ey1ugsuWhkiZ0EDdbKao.jpeg', count: 6, interest: false },
    { name: 'Emily Anderson', profilePicture: 'demo/photos/women-white-young/OIG.XTivnTRoSMJ_1FXXs7Hz.jpeg', count: 5, interest: false },
    { name: 'Raven Howard', profilePicture: 'demo/photos/women-black-young/OIG.B6DmytpI0Ljx.sUkOlf8.jpeg', count: 5, interest: false },
    { name: 'Carlos Martinez', profilePicture: 'demo/photos/men-hispanic-old/OIG.r.zmxd5xSeUjTpL2r9jg.jpeg', count: 4, interest: false },
    { name: 'James Smith', profilePicture: 'demo/photos/men-white-old/OIG.U4ZH5F8w7nkqL.rQ02f9.jpeg', count: 0, interest: false },
    { name: 'Lisa Thomas', profilePicture: 'demo/photos/women-white-old/OIG.kExL8fLo6V9n2N0Oqq.0.jpeg', count: 0, interest: false },
    { name: 'Nathan Rivera', profilePicture: 'demo/photos/men-hispanic-young/OIG.3YoMYN2kmQAmptBsxRG3.jpeg', count: 0, interest: false },
    { name: 'Bruce Carter', profilePicture: 'demo/photos/men-white-old/OIG.HGYd3m8bvhY2GDN2XQDl.jpeg', count: 0, interest: false },
    { name: 'Ava Wong', profilePicture: 'demo/photos/women-asian-young/OIG.qGM9nH5XkTAxOaPJcXky.jpeg', count: 0, interest: false },
    { name: 'Samantha Collins', profilePicture: 'demo/photos/women-white-old/OIG.yxqva2WgATGan_AE2V2s.jpeg', count: 0, interest: false },
  ];



  return (
    <article className={classes.userCard}>
      <div className={classes.userCard__info}>
        <div className={classes.info__icon} style={{ backgroundColor: statusColor(user.currentWorkload, theme) }} />
        <div className={classes.info__name}>{user.name}</div>
        <div className={classes.info__link}>{typeof user.group != "string" && user.group?.name} - {typeof user.office != "string" && user.office?.name}</div>

        <div className={classes.info__link}>{user.email}</div>
        <div className={classes.info__link}>{user.phone}</div>

      </div>
      {/* <div className={classes.userCard__stats}>
                <div className={classes.stats__card}>
                    <div className={classes.stats__title}>30 Day avg.</div>
                    <div className={classes.stats__stat}>4.1</div>
                </div>
                <div className={classes.stats__card}>
                    <div className={classes.stats__title}>7 Day avg.</div>
                    <div className={classes.stats__stat}>6.1</div>
                </div>
            </div> */}

      {detailedReport && detailedReport.length && detailedReport[0] ? (

        <div className={classes.userCard__list}>
          <h2>Matters</h2>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Matter</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell align={'right'}>Lead</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {detailedReport[0].matters && detailedReport[0].matters.map(
                (matter) =>
                  <React.Fragment key={`user-card-matter-${matter._id}`}>
                    <TableRow
                    // onClick={() => {
                    //   handleLink(project, 'project');
                    // }}
                    // style={{ cursor: 'pointer' }}
                    // onMouseEnter={() => project._id && prefetchProject({ id: project._id }, { ifOlderThan: 180 })}


                    >
                      <TableCell>{matter.displayName}<MatterPM matter={matter} userId={userId} /><br /><span className={classes.table_subtitle} style={{ opacity: 0.5 }}>{matter.clientDisplayName}</span></TableCell>
                      <TableCell className={classes.table_subtitle} >{matter.comment}</TableCell>
                      <TableCell align={'right'} style={{ paddingBlock: 0 }}>
                        <div className={classes.table__round}>{matter.resposibleTimekeeperName}</div>
                      </TableCell>

                    </TableRow>
                    <TableRow className={classes.table__spacer} />
                  </React.Fragment>

              )}
            </TableBody>
          </Table>
        </div>
      ) : ''}

      {user.email == "testuser@getwemble.com" ? (
        <>

          <div className={classes.userCard__list}>
            <h2>Matter Types</h2>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Matter Type</TableCell>
                  <TableCell>Number of Matters</TableCell>
                  <TableCell align={'right'}>Interest area</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {matterTypes.map((matter) => (
                  <React.Fragment key={`user-card-matter-${matter.matterType}`}>
                    <TableRow>
                      <TableCell>{matter.matterType}</TableCell>
                      <TableCell>{matter.count}</TableCell>

                      <TableCell align={'right'}>
                        {matter.interest ? (
                          <span className={classes.goldStar} />
                        ) : (
                          <span className={classes.greyStar} />
                        )}
                      </TableCell>

                    </TableRow>
                    <TableRow className={classes.table__spacer} />
                  </React.Fragment>

                ))}
              </TableBody>
            </Table>
          </div>

          <div className={classes.userCard__list}>
            <h2>Client Industries</h2>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Client Type</TableCell>
                  <TableCell>Number of Matters</TableCell>
                  <TableCell align={'right'}>Interest area</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientIndustries.map((client) => (
                  <React.Fragment key={`user-card-client-${client.clientIndustry}`}>
                    <TableRow>
                      <TableCell>{client.clientIndustry}</TableCell>
                      <TableCell>{client.count}</TableCell>
                      <TableCell align={'right'}>
                        {client.interest ? (
                          <span className={classes.goldStar} />
                        ) : (
                          <span className={classes.greyStar} />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.table__spacer} />
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>




          <div className={classes.userCard__list}>
            <h2>Staffed With</h2>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Colleague</TableCell>
                  <TableCell>Number of Matters</TableCell>
                  <TableCell align={'right'}>Interest area</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staffedWith.map((colleague) => (
                  <React.Fragment key={`user-card-colleague-${colleague.name}`}>
                    <TableRow>
                      <TableCell >
                        <Avatar image={colleague.profilePicture} size={36} name={colleague.name} />
                      </TableCell>
                      <TableCell>{colleague.count}</TableCell>
                      <TableCell align={'right'}>
                        {colleague.interest ? (
                          <span className={classes.goldStar} />
                        ) : (
                          <span className={classes.greyStar} />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.table__spacer} />
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>

        </>
      ) : ''}

      {(!organization(currentUser?.company).disableProjects && user.projects && user.projects.length) ? (<div className={classes.userCard__list}>
        <h2>Projects</h2>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align={'right'}>Starts</TableCell>
              <TableCell align={'right'}>Ends</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.projects?.map(
              (project) =>
                typeof project !== 'string' && (
                  <React.Fragment key={`user-card-project-${project._id}`}>
                    <TableRow
                      onClick={() => {
                        handleLink(project, 'project');
                      }}
                      style={{ cursor: 'pointer' }}
                      onMouseEnter={() => project._id && prefetchProject({ id: project._id }, { ifOlderThan: 180 })}


                    >
                      <TableCell>{project.name}</TableCell>
                      <TableCell align={'right'} style={{ paddingBlock: 0 }}>
                        {project.startDate && (
                          <div className={classes.table__date}>{formatDate(project.startDate)}</div>
                        )}
                      </TableCell>
                      <TableCell align={'right'} style={{ paddingBlock: 0 }}>
                        {project.endDate && <div className={classes.table__date}>{formatDate(project.endDate)}</div>}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.table__spacer} />
                  </React.Fragment>
                ),
            )}
          </TableBody>
        </Table>
      </div>
      ) : ''}
      {!organization(currentUser?.company).disableCompetences && (
        <div className={classes.userCard__list}>
          <h2>Competences
          </h2>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align={'right'}>Category</TableCell>
                <TableCell align={'right'}>Skill level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user.skills?.map(
                (skill) =>
                  typeof skill !== 'string' &&
                  typeof skill.competence !== 'string' &&
                  typeof skill.level !== 'string' && (
                    <React.Fragment key={skill._id}>
                      <TableRow
                        onClick={() => {
                          handleLink(skill.competence, 'competence');
                        }}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={() => typeof skill.competence !== 'string' && skill?.competence?._id && prefetchCompetence({ id: skill?.competence?._id }, { ifOlderThan: 180 })}

                      >
                        <TableCell>{skill.competence?.name}</TableCell>
                        <TableCell align={'right'} style={{ paddingBlock: 0 }}>
                          {skill?.competence?.parent?.name && (
                            <>
                              <CompetenceCategory small name={(skill?.competence?.parent?.parent ? ((competences?.find(c => typeof skill?.competence != 'string' && skill?.competence && c._id == skill?.competence?.parent?.parent)?.name + " - ") || '') : '') + skill?.competence?.parent?.name} />
                            </>
                          )}
                        </TableCell>
                        <TableCell align={'right'} style={{ paddingBlock: 0 }}>

                          <CompetenceAverageSkillLevel
                            skill={skill?.level?.level}
                            changeable={currentUser?.admin || currentUser?.companyAdmin || currentUser?.administratorPriviliges || (!organization(currentUser?.company).disableCompetencesEditSelf && currentUser?._id === user._id)}
                            userID={user._id}
                            skillID={skill._id}

                          />
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.table__spacer} />
                    </React.Fragment>
                  ),
              )}
              {(currentUser?.admin || currentUser?.companyAdmin || currentUser?.administratorPriviliges || (!organization(currentUser?.company).disableCompetencesEditSelf && currentUser?._id === user._id)) && (
                <FilterSelect
                  options={[
                    ...competences?.filter(competence => competences.find(c => c.parent?._id === competence._id)).map(c => ({
                      label: (c.parent ? (c.parent.name + " - " + c.name) : c.name),
                      options: competences?.filter(competence => competence.parent?._id === c._id && !competence.root && !user.skills?.find(skill => typeof skill !== 'string' && typeof skill.competence !== 'string' && skill.competence?._id == competence._id))
                        ?.map((competence) => {
                          return {
                            label: competence.name,
                            value: competence._id,
                            type: 'competence',
                          };
                        }),
                    })) || []
                    , {
                      label: 'Competences',
                      options: competences?.filter(competence => !competence.root && !competence.parent && !user.skills?.find(skill => typeof skill !== 'string' && typeof skill.competence !== 'string' && skill.competence?._id == competence._id)).map((competence) => {
                        return {
                          label: competence.name,
                          value: competence._id,
                          type: 'competence',
                        };
                      }),
                    }].filter((v) => v !== null)}
                  onChange={(value) => {
                    user._id && value[0] && value[0].value && addCompetence({ userId: user._id, id: value[0].value })
                    window.document.activeElement && window.document.activeElement instanceof HTMLElement && window.document.activeElement.blur()
                  }}
                  defaultValue={[]}
                  placeholder={'Add competence'}
                  small
                />
              )}

            </TableBody>
          </Table>
        </div>
      )}
      {user.cvpartnerData && user.cvpartnerData.length > 0 && (
        <div className={classes.userCard__list}>
          <h2>CV Partner</h2>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user.cvpartnerData?.map((skill) => (
                <React.Fragment key={skill}>
                  <TableRow
                    onClick={() => {
                      // handleLink(skill, 'competence');
                    }}
                  >
                    <TableCell style={{ borderRadius: 8 }}>{skill}</TableCell>
                  </TableRow>
                  <TableRow className={classes.table__spacer} />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </article>
  );
};
export default UserCard;
