import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import MoveUserModal from './MoveUserModal';
import { UserDto, UserInputErrors } from 'utils/wemble-api.generated';
import {
  useGetCurrentUserQuery,
  useUpdateUserAdminStatusMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
  useResendUserInviteMutation,
  useUpdateUserProfilePictureMutation,
  useRefreshTokenQuery,
} from 'utils/wemble-api';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { notEmpty } from 'utils/helpers';
import { usePrevious } from 'hooks';

const useStyles = makeStyles(() => ({
  profilePicture: {
    position: 'absolute',
    borderRadius: '100%',
    background: '#f3f3f3',
    backgroundSize: '106px',
    textIndent: '106px',
    overflow: 'hidden',
    objectFit: 'cover',
    objectPosition: '50% 0%',
    height: 106,
    width: 106,
  },
  addPicture: {
    position: 'absolute',
    borderRadius: '100%',
    background: '#eee',
    transition: '0.3s ease-out',
    width: '106px',
    height: '106px',
    '&:hover': {
      opacity: '1.0 !important',
    },
    '& svg': {
      width: '50px',
      height: '50px',
      marginLeft: '28px',
      marginTop: '28px',
    },
  },
  fileInput: {
    width: '106px',
    height: '106px',
    position: 'absolute',
    left: 0,
    opacity: 0,
    cursor: 'pointer',
  },
  toggleAdminStatusBtn: {
    position: 'relative',
    float: 'left',
    pointerEvents: 'auto',
    cursor: 'pointer',
    fontWeight: 'normal',
  },
  resendInviteWrapper: {
    float: 'left',
    margin: 0,
    fontSize: 14,
    '& a': {
      color: '#007bff !important',
      cursor: 'pointer',
    },
  },
  changeGroupBtn: {
    position: 'absolute',
    float: 'left',
    pointerEvents: 'auto',
    cursor: 'pointer',
    left: 20,
  },
}));

export type UserModalType = 'administrator' | 'employee' | 'company-administrator';

const schema = yup.object().shape({
  name: yup.string().required('This field is required.'),
  email: yup.string().email('Needs to be a valid email').required('This field is required.'),
});
const UserModal = ({
  isOpen,
  handleClose,
  user,
  type,
  office = undefined,
  group = undefined,
  onAfterUserModified,
}: {
  isOpen: boolean;
  handleClose: () => void;
  user: UserDto | undefined;
  type: UserModalType | undefined;
  office?: string | undefined | null;
  group?: string | undefined | null;
  onAfterUserModified?: () => Promise<void> | void;
}) => {
  const classes = useStyles();
  const isNew = Boolean(!user);
  const [profilePicture, setProfilePicture] = useState(
    user?.profilePicture ? ((user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture) : '',
  );
  const [resendSuccess, setResendSuccess] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const prevUser = usePrevious<UserDto | undefined>(user);

  const { data: currentUser } = useGetCurrentUserQuery();
  const { refetch: refetchToken } = useRefreshTokenQuery();

  const [resendInvite, { isLoading: resendingInvite }] = useResendUserInviteMutation();
  const [removeUser, { isLoading: removingUser }] = useDeleteUserMutation();
  const [updateUserAdminStatus, { isLoading: updatingAdminStatus }] = useUpdateUserAdminStatusMutation();
  const [updateUserProfilePicture] = useUpdateUserProfilePictureMutation();
  const [updateUser, { isLoading: updatingUser }] = useUpdateUserMutation();
  const [createUser, { isLoading: creatingUser }] = useCreateUserMutation();

  const disabled = resendingInvite || removingUser || updatingAdminStatus || updatingUser || creatingUser;

  const { register, formState, reset, handleSubmit, setError, clearErrors } = useForm({
    defaultValues: {
      name: user?.name ?? '',
      email: user?.email ?? '',
      title: user?.title ?? '',
      observer: user?.admin ?? false,
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const onClose = () => {
    setProfilePicture('');
    reset({
      name: '',
      email: '',
      title: '',
      observer: false,
    });
    setResendSuccess(false);
    handleClose();
  };

  useEffect(() => {
    if (isOpen && prevUser?._id !== user?._id) {
      setProfilePicture(
        user?.profilePicture ? ((user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture) : '',
      );
      reset({
        name: user?.name ?? '',
        email: user?.email ?? '',
        title: user?.title ?? '',
        observer: user?.admin ?? false,
      });
      setResendSuccess(false);
    }
  }, [isOpen, user]);

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0 && user?._id) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('image', file);
      updateUserProfilePicture({
        id: user._id,
        // @ts-ignore
        body: formData,
      });
      setProfilePicture(URL.createObjectURL(file));
    }
  };

  const setErrors = (error: any) => {
    if (error?.data?.entityType === 'UserInputErrors') {
      Object.entries(error.data as UserInputErrors).forEach(([key, message]) => {
        if (key === 'name' || key === 'email') {
          setError(key, { message });
        }
      });
    } else if (typeof error?.data?.message === 'string') {
      // @ts-ignore
      setError('server', { message: error?.data?.message });
    }
  };

  const onSubmit = async (data) => {
    clearErrors();
    if (isNew) {
      const res = await createUser({
        userCreationParams: {
          name: data.name,
          email: data.email,
          title: data.title,
          admin: type === 'administrator',
          companyAdmin: type === 'company-administrator',
          group: type === 'company-administrator' ? undefined : group ?? undefined,
          office: type === 'company-administrator' ? undefined : office ?? undefined,
        },
      });
      if ('error' in res) {
        setErrors(res.error);
        return;
      }
      if (onAfterUserModified) await onAfterUserModified();
      onClose();
    } else if (user?._id) {
      const res = await updateUser({
        id: user._id,
        userUpdateParams: {
          name: data.name,
          email: data.email,
          title: data.title,
          admin: Boolean(user.admin),
          observer: type === 'employee' ? undefined : data.observer,
        },
      });
      if ('error' in res) {
        setErrors(res.error);
        return;
      }
      if (user?._id == currentUser?._id) {
        refetchToken();
      }
      if (onAfterUserModified) await onAfterUserModified();
      onClose();
    }
  };

  const handleToggleUserAsAdmin = async (isAdmin: boolean) => {
    if (!user?._id) return;
    const res = await updateUserAdminStatus({
      id: user._id,
      updateUserAdminStatusParams: {
        admin: false,
        administratorPriviliges: !isAdmin,
      },
    });
    if ('error' in res) {
      setErrors(res.error);
      return;
    }
    if (onAfterUserModified) await onAfterUserModified();
    onClose();
  };

  const handleDeleteUser = async () => {
    if (user?._id && window.confirm('Are you sure?')) {
      const res = await removeUser({ id: user._id });
      if ('error' in res) {
        setErrors(res.error);
        return;
      }
      if (onAfterUserModified) await onAfterUserModified();
      onClose();
    }
  };

  // A normal admin should not be able to edit a company admin. Company admin should be filtered from the normal admin views but this is just
  useEffect(() => {
    if (user?.companyAdmin && !currentUser?.companyAdmin) {
      onClose();
    }
  }, [user, currentUser]);

  return (
    <div>
      <Modal isOpen={isOpen} toggle={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={onClose}>
            {isNew ? `Invite new ${type === 'company-administrator' ? 'administrator' : type}` : user?.name}
          </ModalHeader>
          <ModalBody>
            {!user?.companyAdmin && !isNew && (
              <div
                style={{
                  marginLeft: 'calc(50% - 53px)',
                  marginBottom: '15px',
                  height: '106px',
                }}
              >
                <img
                  src={profilePicture}
                  className={classes.profilePicture}
                  alt=""
                  style={{
                    opacity: profilePicture ? 1 : 0,
                  }}
                />
                <span
                  className={classes.addPicture}
                  style={{
                    opacity: profilePicture ? 0 : 1,
                  }}
                >
                  <svg x="0px" y="0px" viewBox="0 0 307.308 307.308">
                    <g id="camera">
                      <path d="M284.909,66.146h-81.345l-16.426-27.595c-1.607-2.698-4.514-4.351-7.654-4.351h-51.662c-3.14,0-6.048,1.653-7.654,4.351   l-16.426,27.595H77.049v-6.082c0-4.919-3.988-8.907-8.907-8.907H35.185c-4.92,0-8.907,3.988-8.907,8.907v6.082h-3.88   C10.027,66.146,0,76.174,0,88.543v162.166c0,12.37,10.027,22.398,22.397,22.398h262.512c12.37,0,22.398-10.028,22.398-22.398   V88.543C307.308,76.174,297.279,66.146,284.909,66.146z M153.653,233.379c-35.21,0-63.753-28.543-63.753-63.754   c0-35.209,28.543-63.753,63.753-63.753c35.21,0,63.753,28.544,63.753,63.753C217.406,204.836,188.863,233.379,153.653,233.379z    M270.935,112.322h-27.91c-4.919,0-8.907-3.988-8.907-8.908c0-4.92,3.988-8.908,8.907-8.908h27.91c4.921,0,8.908,3.988,8.908,8.908   C279.843,108.334,275.855,112.322,270.935,112.322z" />
                      <circle cx="153.653" cy="169.625" r="44.538" />
                    </g>
                  </svg>

                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={onSelectFile}
                    className={classes.fileInput}
                  />
                </span>
              </div>
            )}
            <table className={'table'}>
              <tbody>
                <tr>
                  <th
                    scope="row"
                    className={classNames('control-label', {
                      'text-danger': errors.name,
                    })}
                  >
                    Name
                  </th>
                  <td>
                    <input {...register('name')} required type="text" className={'form-control'} />{' '}
                    {errors.name && <span className="text-danger">{errors.name?.message}</span>}
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    className={classNames('control-label', {
                      'text-danger': errors.email,
                    })}
                  >
                    Email
                  </th>
                  <td>
                    <input {...register('email')} required type="email" className={'form-control'} />{' '}
                    {errors.email && <span className="text-danger">{errors.email?.message}</span>}
                  </td>
                </tr>

                {(user?.admin || user?.administratorPriviliges || user?.companyAdmin) &&
                  (user?.companyAdmin ? currentUser?.companyAdmin : true) && (
                    <tr>
                      <th scope="row">Observer</th>
                      <td>
                        <input {...register('observer')} type="checkbox" />{' '}
                      </td>
                    </tr>
                  )}
                {type === 'employee' && !isNew && (
                  <tr>
                    <th
                      scope="row"
                      className={classNames('control-label', {
                        'text-danger': errors.title,
                      })}
                    >
                      Title
                    </th>
                    <td>
                      <input {...register('title')} type="text" className={'form-control'} />{' '}
                      {errors.title && <span className="text-danger">{errors.title?.message}</span>}
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={2}>
                    {errors['server']?.message && <span className="text-danger">{errors['server'].message}</span>}
                  </td>
                </tr>

                {!isNew && user && !user?.companyAdmin && (
                  <tr>
                    <th>
                      <span
                        onClick={() =>
                          handleToggleUserAsAdmin(Boolean(user?.admin) || Boolean(user?.administratorPriviliges))
                        }
                        className={classNames(classes.toggleAdminStatusBtn, 'text-secondary')}
                      >
                        Make {user?.admin || user?.administratorPriviliges ? 'user' : 'administrator'}
                      </span>
                    </th>
                    <td />
                  </tr>
                )}
              </tbody>
            </table>

            {user && !user.completed && !isNew && (
              <span className={classNames(classes.resendInviteWrapper, 'text-secondary')}>
                Please open the email sent to {user?.email} in order to complete the registration.
                {!resendSuccess ? (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      if (user?._id) {
                        resendInvite({ id: user._id });
                        setResendSuccess(true);
                      }
                    }}
                  >
                    {' '}
                    Resend invite
                  </a>
                ) : (
                  ' Invite resent'
                )}
              </span>
            )}
          </ModalBody>
          <ModalFooter>
            {!isNew && user && (user?.companyAdmin ? currentUser?.companyAdmin : true) && (
              <span
                className={classNames(classes.changeGroupBtn, 'text-secondary')}
                onClick={() => setShowMoveDialog(true)}
              >
                {notEmpty(user.group) && notEmpty(user.office) ? 'Change group' : 'Assign to group'}
              </span>
            )}

            <Button color="secondary" onClick={onClose} disabled={disabled}>
              Cancel
            </Button>

            {!isNew && user && (
              <Button color="danger" onClick={handleDeleteUser} disabled={disabled}>
                Delete
              </Button>
            )}
            {
              <Button color="primary" type="submit" disabled={disabled}>
                {isNew ? 'Invite' : 'Save'}
              </Button>
            }
          </ModalFooter>
        </form>
      </Modal>

      {user && (
        <MoveUserModal
          isOpen={showMoveDialog}
          handleClose={() => setShowMoveDialog(false)}
          user={user}
          onAfterUserModified={onAfterUserModified}
        />
      )}
    </div>
  );
};

export default UserModal;
