import { makeStyles, Theme, useTheme } from '@material-ui/core';
import homeIcon from '../../assets/icons/home.svg';
import officeIcon from '../../assets/icons/office.svg';
import elsewhereIcon from '../../assets/icons/elsewhere.svg';
import courtIcon from '../../assets/icons/court.svg';

import { UserDto } from 'utils/wemble-api.generated';

const useStyles = () => makeStyles((theme) => ({
  profile: {
    position: 'relative',
    height: 100,
    width: 100,
    borderRadius: '50%',
    border: 'solid 5px white',
    [theme.breakpoints.down('xs')]: {
      width: 65,
      height: 65,
      border: 'solid 3px white',

    }
  },
  profile__img: {
    width: '100%',
    overflow: 'hidden',
    borderRadius: '50%',
  },
  profile__initials: {
    backgroundColor: '#efefef',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderRadius: '50%',
    fontSize: 26,
    fontWeight: 600,
  },
  profile__status: {
    position: 'absolute',
    right: -6,
    bottom: -6,
    backgroundColor: 'white',
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: 'solid 3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      filter: 'brightness(0)',
    },
  },
}));

function getInitials(name) {
  const nameSplit = name.toUpperCase().split(' ');
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    return [nameSplit[0].substring(0, 1), nameSplit[nameLength - 1].substring(0, 1)];
  } else if (nameLength === 1) {
    return [nameSplit[0].substring(0, 1), ''];
  } else return '';
}

function statusColor(status: number | null, theme: Theme) {
  switch (status) {
    case 0:
      return theme.palette.blue.b2;
    case 1:
      return theme.palette.green.g4;
    case 2:
      return theme.palette.yellow.y2;
    case 3:
      return theme.palette.red.r3;
    default:
      return '#666';
  }
}

const Profile = ({ data, onLeave }: { data: UserDto; onLeave: boolean, small?: boolean }) => {
  const classes = useStyles()();
  const theme = useTheme();

  return (
    <div className={classes.profile}>
      {data.profilePicture ? (
        <img
          src={(data?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + data?.profilePicture}
          alt={data.name + '-image'}
          className={classes.profile__img}
        />
      ) : (
        <div className={classes.profile__initials}>
          <span style={{ color: statusColor(null, theme) }}>{getInitials(data.name)[0]}</span>
          <span style={{ color: statusColor(null, theme) }}>{getInitials(data.name)[1]}</span>
        </div>
      )}
      {!onLeave && data.atHome !== null && data.onLeave !== null && (
        <div className={classes.profile__status} style={{ borderColor: 'white' }}>
          <img src={data.atHome ? homeIcon : ((data.workplaceForecast || [0])[0] == 3 ? ((typeof data.company === 'string' ? data.company : data.company?._id ?? '') === "5da0c1370b30f993a68d898f" ? officeIcon : ((typeof data.company === 'string' ? data.company : data.company?._id ?? '') == "65f779d34b718376e412163f" ? courtIcon : elsewhereIcon)) : officeIcon)} height={18} alt={data.atHome ? 'home-icon' : 'office-icon'} />
        </div>
      )}
    </div>
  );
};

export default Profile;
