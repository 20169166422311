import { useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import OrderSelect from './shared/OrderSelect';
import {
  compareUsersByExperience,
  compareUsersByUpdated,
  compareUsersByName,
  compareUsersByNegativeWorkload,
  compareUsersByWorkload,
} from 'helpers/compareFunctions';
import ReactLoading from 'react-loading';
import { makeStyles } from '@material-ui/styles';
import UniversalFilterSelect from './shared/UniversalFilterSelect';
import { useGetCurrentUserQuery } from 'utils/wemble-api';
import { UserDto, UserFilterParams } from 'utils/wemble-api.generated';
import UtilizationPage from './Home/UtilizationPage';
import organization from 'utils/organization';
import DetailedPage from './Home/DetailedPage';
import ViewSelect from './shared/ViewSelect';
import StatusCard from './StatusCard/StatusCardV2';

const useStyles = makeStyles(() => ({
  filter_grid: {
    paddingBottom: 16,
  },
  loader: {
    fill: '#59abcb',
    margin: 'auto',
    marginTop: 50,
    marginBottom: 50,
    width: '10%',
  },
  searchField: {
    '& > div': {
      backgroundColor: 'white !important',
    },
    '& input': {
      fontSize: '14px !important'
    }
  },
}));

export type StatusCardListOrderBy = 'updated' | 'name' | 'workload-desc' | 'workload-asc' | 'experience';

const orderOptions: { label: string; value: StatusCardListOrderBy }[] = [
  {
    label: 'Last updated',
    value: 'updated',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Workload (high-low)',
    value: 'workload-desc',
  },
  {
    label: 'Workload (low-high)',
    value: 'workload-asc',
  },
  {
    label: 'Experience',
    value: 'experience',
  },
];

const sortUsers = (order: StatusCardListOrderBy) => (userA: UserDto, userB: UserDto) => {
  switch (order) {
    default:
    case 'updated':
      return compareUsersByUpdated(userA, userB);
    case 'name':
      return compareUsersByName(userA, userB);
    case 'workload-desc':
      return compareUsersByNegativeWorkload(userA, userB);
    case 'workload-asc':
      return compareUsersByWorkload(userA, userB);
    case 'experience':
      return compareUsersByExperience(userA, userB);
  }
};

const sortByIsCurrent = (currentUser: UserDto | null | undefined, order: string) => (userA: UserDto, userB: UserDto) => {
  if (order !== 'updated') {
    return 0;
  }
  if (userA._id === currentUser?._id) {
    return -1;
  }
  if (userB._id === currentUser?._id) {
    return 1;
  }
  return 0;
};

const StatusCardList = ({
  onClick,
  users,
  setFilters,
  isFetching,
  officeOrGroupSelected,
}: {
  onClick: (user: UserDto) => void;
  users: UserDto[] | undefined;
  setFilters: (filters: UserFilterParams) => void;
  isFetching: boolean;
  officeOrGroupSelected: boolean;
}) => {

  const classes = useStyles();
  const { data: currentUser } = useGetCurrentUserQuery();

  const showMatters = organization(currentUser?.company).enableUtilizationView && (organization(currentUser?.company).name != "Synch" || (currentUser?.admin || currentUser?.administratorPriviliges || currentUser?.companyAdmin))


  const views = [{
    label: 'Overview',
    value: 'overview',
  },
  ...(showMatters ? [{
    label: 'Detailed',
    value: 'detailed',
  },
  ...((currentUser?.admin || currentUser?.administratorPriviliges || currentUser?.companyAdmin) ? [
    {
      label: 'Utilization',
      value: 'utilization',
    }] : [{
      label: 'My Utilization',
      value: 'utilization',
    }]),] : []),
  ]





  //   ((organization(currentUser?.company).enableUtilizationView && ((organization(currentUser?.company).name == "Synch" && (currentUser?.admin || currentUser?.administratorPriviliges || currentUser?.companyAdmin)) || ["66309b91113dc300150542f6", "5e425ccb6d4ea9001678e002", "5daeb8637e1c4600165d587f", "5da2f6c75438640016f8da9f"].includes(typeof currentUser?.group == 'string' ? currentUser?.group : (currentUser?.group?._id || '')) || ["Maria Antrea", "Mike Specter"].includes(currentUser?.name || ''))) ? (currentUser?.admin || currentUser?.administratorPriviliges || currentUser?.companyAdmin) ? [{
  //   label: 'Overview',
  //   value: 'overview',
  // },
  // {
  //   label: 'Detailed',
  //   value: 'detailed',
  // },
  // {
  //   label: 'Utilization',
  //   value: 'utilization',
  // }] : [{
  //   label: 'Overview',
  //   value: 'overview',
  // }, {
  //   label: 'Detailed',
  //   value: 'detailed',
  // },
  // {
  //   label: 'My Utilization',
  //   value: 'utilization',
  // }] : [{
  //   label: 'Overview',
  //   value: 'overview',
  // }]);

  const [order, setOrder] = useState((views.length == 1 && orderOptions.find(o => o.value == localStorage.getItem("OverviewPageSortMethod"))?.value) || orderOptions[0].value);
  const [detailedLayout, setLayout] = useState<string>(localStorage.getItem("DetailedPageLayout") || 'timekeeper');
  const [filtersCopy, setFiltersCopy] = useState<UserFilterParams | undefined>();

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'))
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const statusCardSpacing = xs ? 2 : (sm ? 2 : 3);


  const [view, setView] = useState(views[0]);

  return (
    <>
      <Grid container justify={'space-between'}>
        <Grid item xs={12} className={classes.filter_grid}>
          <UniversalFilterSelect
            type={view.value == "detailed" && detailedLayout === "matter" ? 'matters' : 'users'}
            setFilters={(filters) => { setFilters(filters); setFiltersCopy(filters) }}
            showAllOffices
            showAllGroups
            saveFiltering
            searchFieldClassName={classes.searchField}
            requireOfficeAndGroup={false}
            filteredUsers={users}
            skip={isFetching}

          >
            {views.length == 1 ? (<OrderSelect
              options={orderOptions}
              onChange={(order) => {
                setOrder(order.value);
                localStorage.setItem("OverviewPageSortMethod", order.value)
              }}
              defaultValue={orderOptions.find(o => o.value == localStorage.getItem("OverviewPageSortMethod")) || orderOptions[0]}
            />) : <ViewSelect
              options={views}
              onChange={(view) => {
                setView(view);
              }}
              defaultValue={views[0]}
            />}

          </UniversalFilterSelect>
        </Grid>
      </Grid>


      {view.value == "utilization" && (
        <UtilizationPage filters={filtersCopy} filteredUsers={users} />)}

      {view.value == "detailed" && (
        <DetailedPage filters={filtersCopy} filteredUsers={users} setLayout={setLayout} />)}


      {view.value == "overview" && (
        isFetching ? (
          <ReactLoading type={'spinningBubbles'} className={classes.loader} />
        ) : (
          officeOrGroupSelected && (
            <Grid container spacing={statusCardSpacing} >
              {[...(users ?? [])]
                ?.sort(sortUsers(order))
                .sort(sortByIsCurrent(currentUser, order))
                .map((employee) => (
                  <StatusCard key={`home-user-${employee._id}`}
                    id={employee._id ?? ''}
                    orderOption={order}
                    handleClick={() => {
                      onClick(employee);
                    }} />
                ))}
            </Grid>
          )
        ))}
    </>
  );
};

export default StatusCardList;
