import { makeStyles } from '@material-ui/core';
import ReactDatePicker from 'react-datepicker';
import '../../assets/datePickerCustomStyling.css';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.gray.g3,
    borderRadius: 4
  },
  datePicker: {
    transition: '0.2s',
    height: 48,
  },
  input: {
    height: 48,
    lineHeight: '48px',
    fontWeight: 500,
    width: '100%',
    padding: '0 12px',
    transition: '0.2s',
    backgroundColor: theme.palette.gray.g3,
    cursor: 'pointer',
  },
}));

const DatePicker = ({ selected, onChange }: { selected: Date | string; onChange: (value: Date) => void }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.datePicker}>
          <ReactDatePicker
            wrapperClassName="datePickerCustomStyling"
            selected={selected}
            onChange={onChange}
            calendarStartDay={1}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            customInput={
              <button type="button" className={classes.input} style={selected ? { opacity: 1 } : undefined}>
                {typeof selected !== 'string' && selected?.toISOString().split('T')[0]}
              </button>
            }
            disabledKeyboardNavigation
          />
        </div>
      </div>
    </>
  );
};

export default DatePicker;
