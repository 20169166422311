import { useEffect, useState } from 'react';
import List from '../common/List';
import GroupModal from './GroupModal';
import { Row, Col, Button as FilledButton } from 'reactstrap';
import UserModal, { UserModalType } from '../user/UserModal';
import SkillModal from './SkillModal';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { ReactComponent as MSLogo } from 'assets/icons/ms-logo.svg';
import {
  useGetGroupsQuery,
  useGetLevelOfSkillsQuery,
  useGetUsersQuery,
  useUpdateAzureTenantMutation,
  useRemoveAzureTenantMutation,
  useGetCompanyQuery,
  useGetProjectStagesQuery,
  useGetCurrentUserQuery,
  useUpdateCompanyMutation,
} from 'utils/wemble-api';
import { LevelOfSkillDto, ProjectCategoryDto, ProjectStageDto, useGetProjectCategoriesQuery, UserDto } from 'utils/wemble-api.generated';
import InviteADUsersModal from 'components/user/InviteADUsersModal';
import ProjectStageModal from './ProjectStageModal';
import organization from 'utils/organization';
import ProjectCategoryModal from './ProjectCategoryModal';
import SmtpModal from './SmtpModal';
import SsoModal from './SsoModal';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({

  code__input: {
    backgroundColor: 'white',
    fontFamily: 'monospace',
    borderRadius: '.25rem',
    padding: '.75rem 1.25rem',
    width: '100%',
    transition: '0.1s',
    height: '300px',
    border: '1px solid rgba(0,0,0,.125)',
    position: 'relative',
    resize: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-scrollbar': {
      width: 2,
    },
    display: '-webkit-box',
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    fontSize: 14,

  },
  code__preview: {
    backgroundColor: 'white',
    borderRadius: '.25rem',
    padding: '.75rem',
    width: '100%',

  }
}))



const GroupsPage = () => {
  const classes = useStyles();

  const history = useHistory();
  const { search, pathname } = useLocation();

  const [selectedUser, setSelectedUser] = useState<UserDto | undefined>();
  const [userModalType, setUserModalType] = useState<UserModalType | undefined>();

  const [selectedSkill, setSelectedSkill] = useState<LevelOfSkillDto | undefined>();
  const [selectedProjectStage, setSelectedProjectStage] = useState<ProjectStageDto | undefined>();
  const [selectedProjectCategory, setSelectedProjectCategory] = useState<ProjectCategoryDto | undefined>();

  const [showSkillModal, setShowSkillModal] = useState(false);
  const [showProjectStageModal, setShowProjectStageModal] = useState(false);
  const [showProjectCategoryModal, setShowProjectCategoryModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showAzureUserModal, setShowAzureUserModal] = useState(false);
  const [showSmtpModal, setShowSmtpModal] = useState(false);
  const [showSsoModal, setShowSsoModal] = useState(false);


  const { data: levelOfSkills } = useGetLevelOfSkillsQuery();
  const { data: projectStages } = useGetProjectStagesQuery();
  const { data: projectCategories } = useGetProjectCategoriesQuery();

  const { data: groupsRes } = useGetGroupsQuery();
  const { data: users } = useGetUsersQuery();
  const { data: company, refetch: refetchCompany } = useGetCompanyQuery();
  const [updateAzureTenant] = useUpdateAzureTenantMutation();
  const [removeAzureTenant, { isLoading }] = useRemoveAzureTenantMutation();
  const [updateCompany] = useUpdateCompanyMutation();

  const admins = users?.filter((user) => user.companyAdmin);
  const groups = groupsRes?.groups;

  const [customHtml, setCustomHtml] = useState(company?.customHtml);

  const handleOpenUserModal = (type: UserModalType) => (user?: UserDto | undefined) => {
    setSelectedUser(user);
    setUserModalType(type);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(undefined);
    setUserModalType(undefined);
  };

  const handleToggleSkillModal = (open: boolean) => (skill?: LevelOfSkillDto | undefined) => {
    setSelectedSkill(skill);
    setShowSkillModal(open);
  };

  const handleToggleProjectStageModal = (open: boolean) => (stage?: ProjectStageDto | undefined) => {
    setSelectedProjectStage(stage);
    setShowProjectStageModal(open);
  };

  const handleToggleProjectCategoryModal = (open: boolean) => (category?: ProjectCategoryDto | undefined) => {
    setSelectedProjectCategory(category);
    setShowProjectCategoryModal(open);
  };


  const { data: user } = useGetCurrentUserQuery();

  if (!user) return null;

  const organizationConfig = organization(user.company);


  useEffect(() => {
    setCustomHtml(company?.customHtml)
  }, [company])

  // Update user data in modal if users list changes.
  useEffect(() => {
    setSelectedUser((prevSelectedUser) => users?.find((u) => u._id === prevSelectedUser?._id));
  }, [users]);

  useEffect(() => {
    const searchObject = Object.fromEntries(
      search
        .substring(1)
        .split('&')
        .map((v) => v.split('=')),
    );
    if (searchObject['tenant']) {
      updateAzureTenant({
        body: {
          tenantId: searchObject['tenant'],
        },
      }).then(() => {
        history.replace(pathname);
        refetchCompany();
      });
    }
  }, [search, pathname, history]);

  const handleRemoveAzureADTenant = async () => {
    await removeAzureTenant();
    await refetchCompany();
  };

  return (
    <Row>
      <Col
        sm="12"
        md={{
          size: 8,
          offset: 2,
        }}
      >
        <InviteADUsersModal
          open={showAzureUserModal}
          onClose={() => setShowAzureUserModal(false)}
          azureADTenantId={company?.azureADTenantId}
        />
        <UserModal
          isOpen={userModalType !== undefined}
          handleClose={handleCloseUserModal}
          user={selectedUser}
          type={userModalType}
        />

        <GroupModal title="New Group" isOpen={showGroupModal} onClose={() => setShowGroupModal(false)} />
        <SmtpModal data={company} isOpen={showSmtpModal} onClose={() => {
          setShowSmtpModal(false);
          refetchCompany()
        }} />
        <SsoModal data={company} isOpen={showSsoModal} onClose={() => {
          setShowSsoModal(false);
          refetchCompany()
        }} />



        <SkillModal
          title={selectedSkill?.name ?? "New skill level"}
          isOpen={showSkillModal}
          data={selectedSkill}
          onClose={handleToggleSkillModal(false)}
        />
        <ProjectStageModal
          title={selectedProjectStage?.name ?? "New project stage"}
          isOpen={showProjectStageModal}
          data={selectedProjectStage}
          onClose={handleToggleProjectStageModal(false)}
        />
        <ProjectCategoryModal
          title={selectedProjectCategory?.name ?? "New project category"}
          isOpen={showProjectCategoryModal}
          data={selectedProjectCategory}
          onClose={handleToggleProjectCategoryModal(false)}
        />


        <Grid container justify="space-between" alignItems="center">
          <h2
            style={{
              marginBottom: 24,
              marginTop: 20,
            }}
          >
            {groupsRes?.companyName || <br />}
          </h2>

          <div>
            <Grid container direction="column" alignItems="flex-end">
              {company?.azureADTenantId && company?.azureADTenantId.length > 0 && (
                <Button startIcon={<MSLogo />} onClick={() => setShowAzureUserModal(true)} disabled={isLoading}>
                  Add users
                </Button>
              )}
              {company?.azureADTenantId && company?.azureADTenantId.length > 0 ? (
                <Button
                  startIcon={<MSLogo />}
                  onClick={() => {
                    if (window.confirm('Are you sure?')) handleRemoveAzureADTenant();
                  }}
                  disabled={isLoading}
                >
                  Remove from my organization
                </Button>
              ) : process.env.REACT_APP_AAD_APP_CLIENT_ID ? (
                <Button
                  startIcon={<MSLogo />}
                  href={`https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_AAD_APP_CLIENT_ID}&redirect_uri=${window.location.href}`}
                >
                  Add to my organization
                </Button>
              ) : null}
            </Grid>
          </div>
        </Grid>
        <List
          dataType="user"
          data={admins}
          title={'Administrators'}
          handleItemClick={handleOpenUserModal('company-administrator')}
          handleAddItem={handleOpenUserModal('company-administrator')}
        />

        <List
          dataType="group"
          data={groups}
          title={'Groups'}
          handleAddItem={() => setShowGroupModal(true)}
          handleItemClick={(group) => history.push(`/groups/${group._id}`)}
          isGroup
        />
        <List
          dataType="levelOfSkill"
          data={levelOfSkills}
          title={'Skill levels'}
          handleAddItem={handleToggleSkillModal(true)}
          handleItemClick={handleToggleSkillModal(true)}
          isSkillView
          isGroup
        />
        {organizationConfig.enableProjectStages && (<List
          dataType="projectStage"
          data={projectStages}
          title={'Project stages'}
          handleAddItem={handleToggleProjectStageModal(true)}
          handleItemClick={handleToggleProjectStageModal(true)}
          isGroup
        />)}
        {organizationConfig.enableProjectStages && organizationConfig.projectInvoicing && (<List
          dataType="projectCategory"
          data={projectCategories}
          title={'Project categories'}
          handleAddItem={handleToggleProjectCategoryModal(true)}
          handleItemClick={handleToggleProjectCategoryModal(true)}
          isGroup
        />)}
        {/*@ts-ignore*/}
        {((window._env_ && window._env_.APP_ON_RC) || (company && company._id == "5d7932251a047744d0b16877")) && (
          <>
            <FilledButton color="primary" style={{ marginLeft: 8 }} onClick={() => setShowSmtpModal(true)}>
              SMTP Config
            </FilledButton>

            <FilledButton color="primary" style={{ marginLeft: 8 }} onClick={() => setShowSsoModal(true)}>
              SSO/SAML2 Config
            </FilledButton>
          </>
        )}

        {
          organization(company?._id).enableCustomHtml && (<>
            <h4>Custom html</h4>
            <p>The following text strings get replaced with actual values: USER_NAME, USER_CUSTOM_ID, USER_WEMBLE_ID, USER_EMAIL, USER_PHOTO_URL</p>
            {customHtml && (<><h5>Live preview</h5><div className={classes.code__preview}
              dangerouslySetInnerHTML={{ __html: customHtml.replaceAll('USER_NAME', user?.name || '').replaceAll('USER_CUSTOM_ID', user?.name?.toLowerCase().replaceAll(' ', '-') || '').replaceAll('USER_EMAIL', user?.email || '').replaceAll('USER_WEMBLE_ID', user?._id || '').replaceAll("USER_PHOTO_URL", user.profilePicture ? ((user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture) : '') }}
            /></>)}
            <br />
            <h5>HTML Code            <FilledButton style={{ float: 'right' }} color="primary" disabled={customHtml == company?.customHtml} onClick={() => updateCompany({ companyUpdateParams: { name: company?.name || '', customHtml: customHtml } })}>Save</FilledButton>
            </h5>
            <br />
            <textarea
              id="edit_html"
              className={classes.code__input}
              value={customHtml}
              onChange={e => setCustomHtml(e.target.value)} />
            <br />

            <br /><br /><br />

          </>
          )
        }



      </Col>
    </Row>
  );
};

export default GroupsPage;
